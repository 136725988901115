<template>
  <div class="holiday-container">
    <div class="head-contain">
      <div class="fanhui-contain" @click="backClick">
        <en-icon class="icon" name="fanhui" size="18px"></en-icon>
        <div>我的假期</div>
      </div>
      <en-button
        class="button"
        icon="rizhi"
        type="text"
        icon-size="16"
        @click="holidayLogClick"
      >
        假期日志
      </en-button>
    </div>

    <div class="charts-contain">
      <holidayChart :charts-data="holidayDataList"></holidayChart>
    </div>

    <holidayLog
      v-if="holidayLogShow"
      :dialog-visible.sync="holidayLogShow"
    ></holidayLog>

    <holidayDetail
      v-if="holidayDetailShow"
      :dialog-visible.sync="holidayDetailShow"
    ></holidayDetail>
  </div>
</template>

<script>
import holidayLog from "./holidayLog";
import holidayDetail from "./holidayDetail";
import holidayChart from "./holidayChart";
import hrData from "./hrData";

export default {
  name: "MyHoliday",
  components: {
    holidayLog,
    holidayDetail,
    holidayChart
  },
  data() {
    return {
      holidayLogShow: false,
      holidayDetailShow: false
    };
  },
  mixins: [hrData],
  activated() {
    this.requestMyHolidayBalance();
  },
  methods: {
    backClick() {
      this.$router.go(-1);
    },
    holidayLogClick() {
      this.holidayLogShow = true;
    },
    holidayDetailClick() {
      this.holidayDetailShow = true;
    },
    enPieDoughnutDidClick(param) {
      this.holidayDetailShow = true;
      const msg = `点击了:${param.data.name}:${param.data.value}${param.data.unit}`;
      this.$notify({
        title: "标题名称",
        message: msg
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.holiday-container {
  background: #FFFFFF;
  .head-contain {
    height: 49px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8ecf2;
    .fanhui-contain {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      color: #333;
      .icon {
        margin-left: 15px;
        color: #a9b5c6;
      }
    }
    .button {
      margin-right: 20px;
      color: #636c78;
    }
  }
  .charts-contain {
    height: calc(100% - 76px);
    width: auto;
    .pie-chart {
      height: 100%;
      width: 100%;
    }
  }
}
</style>

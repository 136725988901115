<template>
  <div class="dialog-contain">
    <el-dialog title="" :show-close="false" :visible.sync="dialogVisible">
      <div class="body-contain">
        <span class="title">请假明细</span>
        <div @click="closeClick">
          <en-icon
            name="guanbi-danchuang"
            class="close-icon"
            size="14px"
            :style="{ fill: '#A9B5C6' }"
          ></en-icon>
        </div>
        <div class="table-contain">
          <en-table border stripe :data="tableData">
            <en-table-column type="index" label="序号" width="70">
            </en-table-column>
            <en-table-column prop="name" label="姓名" width="137" sortable>
            </en-table-column>
            <en-table-column prop="depart" label="部门" width="137" sortable>
            </en-table-column>
            <en-table-column prop="type" label="请假类型" width="124" sortable>
            </en-table-column>
            <en-table-column prop="date" label="请假时间" width="114" sortable>
            </en-table-column>
            <en-table-column
              prop="timeLong"
              label="请假时长(h)"
              width="137"
              sortable
            >
            </en-table-column>
            <en-table-column prop="remark" label="备注"> </en-table-column>
          </en-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "TakeLeaveDetails",
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tableData: [
        {
          name: "王小鸭",
          depart: "财务部",
          type: "事假",
          date: "2016-04-30",
          timeLong: 2,
          remark: "备注信息第0条"
        }
      ]
    };
  },
  mounted() {
    for (let index = 0; index < 20; index++) {
      this.tableData.push({
        name: "王小鸭",
        depart: index % 3 ? "技术研发部" : "财务部",
        type: index % 5 ? "事假" : "带薪年假",
        date: `2016-05-${index + 1 < 10 ? `0${index + 1}` : index + 1}`,
        timeLong: Math.floor(Math.random() * 8) + 1,
        remark: `备注信息第${index}条`
      });
    }
  },
  watch: {
    dialogVisible(val) {
      this.$emit("update:dialogVisible", val);
    }
  },
  methods: {
    closeClick() {
      this.dialogVisible = false;
    },
    confirmClick() {
      this.$notify({
        type: "success",
        title: "温馨提示",
        message: "您选择了"
      });
      // this.holidayLogVisible = false;
      // this.$emit("getTripOccupyValue", this.selectData);
    }
  }
};
</script>

<style lang="scss" scoped>
.body-contain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 1100px;
  height: 600px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 4px;

  .title {
    position: absolute;
    left: 20px;
    top: 20px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 14px;
    height: 14px;
  }
  .table-contain {
    border: 1px solid #dce5ec;
    margin: 52px 20px 5px 20px;
    height: 508px;
    overflow-y: auto;
  }
}
.dialog-contain {
  & /deep/ .el-dialog__header {
    padding: 0;
    margin: 0;
    height: 0;
  }
  & /deep/ .el-dialog__body {
    padding: 0;
    margin: 0;
  }
}
</style>

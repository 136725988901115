<template>
  <div class="attendance-list">
    <en-title-card name="考勤查询">
      <div slot="left">
        <en-icon
          name="fanhui"
          size="small"
          class="attendance-list-back-icon"
          @click.native="backToAttendance"
        >
        </en-icon>
      </div>
      <div slot="right" class="attendance-list-right">
        <div class="attendance-header-item" @click="showFullCharts">
          <en-icon name="quanping" color="#FFAD2C"> </en-icon>
          全屏
        </div>
        <div class="attendance-header-item">
          <en-icon name="daochu-kuaijiecaozuo" color="#26C393"> </en-icon>
          导出
        </div>
      </div>
    </en-title-card>
    <div class="attendance-container">
      <!-- Start 过滤区域 -->
      <div class="filter">
        <div class="filter-item">
          <div class="label">
            考勤日期
          </div>
          <div class="content">
            <el-date-picker
              v-model="filterInfo.dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="filter-item">
          <div class="label">
            范围
          </div>
          <div class="content">
            <el-select v-model="filterInfo.range" placeholder="请选择">
              <el-option
                v-for="item in rangeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
                {{ item.name }}
              </el-option>
            </el-select>
          </div>
        </div>
        <en-button class="filter-item">
          查询
        </en-button>
      </div>
      <!-- End 过滤区域 -->
      <!-- Start 图表区域 -->
      <div class="charts-container">
        <div class="tool-bar">
          <en-button-list
            v-model="activeCharts"
            :list="chartsList"
            @change="setChartsInfo"
          >
          </en-button-list>
          <en-icon
            name="qitafenlei"
            size="18"
            class="icon-toggle"
            @click.native="showDetails"
          >
          </en-icon>
        </div>
        <div id="chartsContainer" class="charts-content"></div>
      </div>
      <!-- End 图表区域 -->
      <!-- Start 详情信息 -->
      <div class="details-list">
        <div
          v-for="(item, index) in detailList"
          :key="index"
          class="details-item"
        >
          <div class="details-item-num">
            {{ item.num }}
          </div>
          <div class="details-item-label">
            {{ item.label }}
          </div>
        </div>
      </div>
      <!-- End 详情信息 -->
      <!-- Start 列表 -->
      <en-table
        :data="tableData"
        :tree-config="{ children: 'children', hasChildren: 'hasChildren' }"
        border
      >
        <en-table-column prop="dept" label="部门" width="180">
        </en-table-column>
        <en-table-column
          prop="shouldAttendNum"
          label="应出勤（人）"
          width="180"
        >
        </en-table-column>
        <en-table-column prop="attendanceNum" label="实出勤（人）">
        </en-table-column>
        <en-table-column prop="absenceNum" label="缺勤（人）">
        </en-table-column>
        <en-table-column prop="takeLeaveNum" label="请假（人）">
        </en-table-column>
        <en-table-column prop="travelNum" label="出差（人）"> </en-table-column>
        <en-table-column prop="overTimeNum" label="加班（人）">
        </en-table-column>
        <en-table-column prop="outSideNum" label="外出（人）">
        </en-table-column>
        <en-table-column prop="abnormalPersonalNum" label="异常人次">
        </en-table-column>
        <en-table-column prop="uncheckedNum" label="未打卡（次）">
        </en-table-column>
        <en-table-column prop="lateNum" label="迟到（次）"> </en-table-column>
        <en-table-column prop="leaveEarlyNum" label="早退（次）">
        </en-table-column>
        <en-table-column prop="absenteeismNum" label="旷工（次）">
        </en-table-column>
        <en-table-column prop="abnormalNum" label="异常（次）">
        </en-table-column>
      </en-table>
      <!-- End 列表 -->
    </div>
    <!-- 图表 -->
    <fullscreen
      ref="fullscreen"
      :fullscreen.sync="showFullScreen"
      :class="{ 'full-screen-charts': showFullScreen }"
    >
      <en-top-menu
        v-if="showFullScreen"
        :divider="`true`"
        menu-name="考勤报表名称"
      >
        <div
          slot="right"
          class="en-top-menu-right"
          @click="showFullScreen = false"
        >
          <en-icon name="tuichuquanping" size="small"> </en-icon>
          退出全屏
        </div>
      </en-top-menu>
      <div id="fullCharts" class="full-charts"></div>
      <div v-if="showFullScreen" class="play-btn">
        <en-icon
          name="xiayiye1"
          color="#ABABAB"
          @click.native="changeCharts('prev')"
        >
        </en-icon>
        <en-icon
          :name="play ? 'bofang-kanban' : 'zanting-kanban'"
          class="play-icon"
          color="#ffffff"
          @click.native="togglePlayStatus"
        >
        </en-icon>
        <en-icon
          name="xiayiye"
          color="#ABABAB"
          @click.native="changeCharts('next')"
        >
        </en-icon>
      </div>
    </fullscreen>

    <!-- Start 请假明细 -->
    <take-leave-details
      v-if="showDetailsDialog"
      :dialogVisible.sync="showDetailsDialog"
    >
    </take-leave-details>
    <!-- End 请假明细 -->
  </div>
</template>

<script>
import takeLeaveDetails from "./dialog/takeLeaveDetails";

export default {
  name: "AttendanceList",
  components: { takeLeaveDetails },
  data() {
    return {
      list: [],
      chartsData: {},
      filterInfo: {
        dateRange: [],
        range: ""
      },
      rangeList: [
        {
          id: 1,
          name: "测试"
        },
        {
          id: 2,
          name: "测试二"
        }
      ],
      activeCharts: "dept",
      chartsList: [
        {
          id: "dept",
          name: "部门",
          iconName: "fenxijianmotubiao1"
        },
        {
          id: "date",
          name: "日期",
          iconName: "fenxijianmotubiao7"
        },
        {
          id: "personal",
          name: "个人",
          iconName: "fenxijianmotubiao3"
        }
      ],
      detailList: [
        {
          num: 56,
          label: "缺勤"
        },
        {
          num: 20,
          label: "未打卡"
        },
        {
          num: 10,
          label: "迟到"
        },
        {
          num: 12,
          label: "早退"
        },
        {
          num: 11,
          label: "旷工"
        },
        {
          num: 12,
          label: "异常"
        },
        {
          num: 11,
          label: "请假"
        },
        {
          num: 12,
          label: "出差"
        },
        {
          num: 11,
          label: "外出"
        },
        {
          num: 11,
          label: "加班"
        }
      ],
      tableData: [],
      chartsInfo: null,
      showDetailsDialog: false,
      showFullScreen: false,
      play: false,
      intervalInfo: null
    };
  },
  watch: {
    showFullScreen(nVal) {
      setTimeout(() => {
        if (nVal) {
          this.resetChartsInfo();
          this.play = true;
          this.autoPlay();
        } else {
          clearInterval(this.intervalInfo);
          this.setChartsInfo();
        }
      }, 100);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getTableData();
      setTimeout(() => {
        this.setChartsInfo();
      }, 50);
    });
  },
  methods: {
    /**
     * @description 设置图表信息
     */
    setChartsInfo() {
      // this.chartsInfo ? this.chartsInfo.clear() : "";
      this.chartsInfo = this.$eCharts.init(
        document.getElementById("chartsContainer")
      );
      if (this.activeCharts === "dept") {
        this.setDeptChartsInfo();
      }
      if (this.activeCharts === "date") {
        this.setDateChartsInfo();
      }
      if (this.activeCharts === "personal") {
        this.setPersonalChartsInfo();
      }
    },
    /**
     * @description 重绘图表信息
     */
    resetChartsInfo() {
      this.chartsInfo.clear();
      this.chartsInfo = this.$eCharts.init(
        document.getElementById("fullCharts")
      );
      if (this.activeCharts === "dept") {
        this.setDeptChartsInfo();
      }
      if (this.activeCharts === "date") {
        this.setDateChartsInfo();
      }
      if (this.activeCharts === "personal") {
        this.setPersonalChartsInfo();
      }
    },
    /**
     * @description 设置部门考勤报表统计图信息
     */
    setDeptChartsInfo() {
      const option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: { show: false },
        series: [
          {
            name: "出勤人数占比",
            type: "pie",
            radius: ["50%", "70%"],
            data: [
              {
                value: 55,
                name: "实出勤人数"
              },
              {
                value: 310,
                name: "应出勤人数"
              }
            ]
          }
        ]
      };
      this.chartsInfo.setOption(option);
    },
    /**
     * @description 设置日期考勤报表统计图信息
     */
    setDateChartsInfo() {
      const fullScreen = {
        tooltip: { trigger: "axis" },
        legend: { show: false },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
          axisLabel: {
            show: true,
            textStyle: {
              color: "#ffffff", // 更改坐标轴文字颜色
              fontSize: 12 // 更改坐标轴文字大小
            }
          },
          axisLine: { lineStyle: { color: "#ffffff" } }
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            textStyle: {
              color: "#ffffff", // 更改坐标轴文字颜色
              fontSize: 12 // 更改坐标轴文字大小
            }
          },
          axisLine: { lineStyle: { color: "#ffffff" } }
        },
        series: [
          {
            name: "邮件营销",
            type: "line",
            stack: "总量",
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: "联盟广告",
            type: "line",
            stack: "总量",
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: "视频广告",
            type: "line",
            stack: "总量",
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: "直接访问",
            type: "line",
            stack: "总量",
            data: [320, 332, 301, 334, 390, 330, 320]
          },
          {
            name: "搜索引擎",
            type: "line",
            stack: "总量",
            data: [820, 932, 901, 934, 1290, 1330, 1320]
          }
        ]
      };
      const option = {
        tooltip: { trigger: "axis" },
        legend: { show: false },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"]
        },
        yAxis: { type: "value" },
        series: [
          {
            name: "邮件营销",
            type: "line",
            stack: "总量",
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: "联盟广告",
            type: "line",
            stack: "总量",
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: "视频广告",
            type: "line",
            stack: "总量",
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: "直接访问",
            type: "line",
            stack: "总量",
            data: [320, 332, 301, 334, 390, 330, 320]
          },
          {
            name: "搜索引擎",
            type: "line",
            stack: "总量",
            data: [820, 932, 901, 934, 1290, 1330, 1320]
          }
        ]
      };
      this.chartsInfo.setOption(this.showFullScreen ? fullScreen : option);
    },
    /**
     * @description 设置个人考勤报表统计图信息
     */
    setPersonalChartsInfo() {
      const personalList = [];
      const dataList = [];
      for (let i = 0; i < 10; i++) {
        dataList.push({
          date: `2019/12/${i}`,
          list: []
        });
        personalList.push(`张___${i}`);
      }
      dataList.foreEach((item) => {
        for (let i = 0; i < 10; i++) {
          item.list.push((Math.random() * 100).toFixed(2));
        }
      });
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "shadow" }
        },
        xAxis: {
          type: "category",
          data: personalList
        },
        yAxis: [{ type: "value" }],
        series: dataList.map((item) => ({
          name: item.date,
          type: "bar",
          data: item.list
        }))
      };
      const screenOption = {
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "shadow" }
        },
        xAxis: {
          type: "category",
          data: personalList,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#ffffff", // 更改坐标轴文字颜色
              fontSize: 12 // 更改坐标轴文字大小
            }
          },
          axisLine: { lineStyle: { color: "#ffffff" } }
        },
        yAxis: [
          {
            type: "value",
            axisLabel: {
              show: true,
              textStyle: {
                color: "#ffffff", // 更改坐标轴文字颜色
                fontSize: 12 // 更改坐标轴文字大小
              }
            },
            axisLine: { lineStyle: { color: "#ffffff" } }
          }
        ],
        series: dataList.map((item) => ({
          name: item.date,
          type: "bar",
          data: item.list
        }))
      };
      this.chartsInfo.setOption(this.showFullScreen ? screenOption : option);
    },
    /**
     * @description 获取表格数据
     */
    getTableData() {
      for (let i = 0; i < 10; i++) {
        this.tableData.push({
          id: (Math.random() * 213213213213).toFixed(0),
          dept: `部门___${i}`,
          shouldAttendNum: (Math.random() * 39).toFixed(0),
          attendanceNum: (Math.random() * 39).toFixed(0),
          absenceNum: (Math.random() * 39).toFixed(0),
          takeLeaveNum: (Math.random() * 39).toFixed(0),
          travelNum: (Math.random() * 39).toFixed(0),
          overTimeNum: (Math.random() * 39).toFixed(0),
          outSideNum: (Math.random() * 39).toFixed(0),
          abnormalPersonalNum: (Math.random() * 39).toFixed(0),
          uncheckedNum: (Math.random() * 39).toFixed(0),
          lateNum: (Math.random() * 39).toFixed(0),
          leaveEarlyNum: (Math.random() * 39).toFixed(0),
          absenteeismNum: (Math.random() * 39).toFixed(0),
          abnormalNum: (Math.random() * 39).toFixed(0),
          hasChildren: true,
          children: [
            {
              id: (Math.random() * 213213213213).toFixed(0),
              dept: `部门___${i}`,
              shouldAttendNum: (Math.random() * 39).toFixed(0),
              attendanceNum: (Math.random() * 39).toFixed(0),
              absenceNum: (Math.random() * 39).toFixed(0),
              takeLeaveNum: (Math.random() * 39).toFixed(0),
              travelNum: (Math.random() * 39).toFixed(0),
              overTimeNum: (Math.random() * 39).toFixed(0),
              outSideNum: (Math.random() * 39).toFixed(0),
              abnormalPersonalNum: (Math.random() * 39).toFixed(0),
              uncheckedNum: (Math.random() * 39).toFixed(0),
              lateNum: (Math.random() * 39).toFixed(0),
              leaveEarlyNum: (Math.random() * 39).toFixed(0),
              absenteeismNum: (Math.random() * 39).toFixed(0),
              abnormalNum: (Math.random() * 39).toFixed(0)
            }
          ]
        });
      }
    },
    /**
     * @description 显示详情弹出框
     */
    showDetails() {
      this.showDetailsDialog = true;
    },
    /**
     * @description 全屏展现图表
     */
    showFullCharts() {
      this.showFullScreen = true;
    },
    /**
     * @description 切换图表
     * @param type
     */
    changeCharts(type) {
      let currentIndex;
      let currentNum;
      this.chartsList.forEach((item, index) => {
        if (item.id === this.activeCharts) {
          currentIndex = index;
        }
      });
      if (type === "prev") {
        if (currentIndex === 0) {
          currentNum = this.chartsList.length - 1;
        } else {
          currentNum = currentIndex - 1;
        }
      } else if (currentIndex === this.chartsList.length - 1) {
        currentNum = 0;
      } else {
        currentNum = currentIndex + 1;
      }
      this.activeCharts = this.chartsList[currentNum].id;
      this.resetChartsInfo();
    },
    /**
     * @description 是否需要自动切换
     */
    togglePlayStatus() {
      this.play = !this.play;
      if (this.play) {
        this.autoPlay();
      } else {
        clearInterval(this.intervalInfo);
      }
    },
    /**
     * @description 自动播放
     */
    autoPlay() {
      this.intervalInfo = setInterval(() => {
        this.changeCharts("next");
      }, 5000);
    },
    backToAttendance() {
      this.$router.push({ path: "/attendance" });
    }
  }
};
</script>

<style lang="scss" scoped>
.attendance-list {
  background-color: #fff;
  .en-title-card {
    position: relative;
    padding-left: 40px;

    .attendance-list-back-icon {
      cursor: pointer;
      position: absolute;
      left: 14px;
      top: 16px;
    }
  }

  .en-title-card {
    .attendance-list-right {
      display: flex;
      justify-content: flex-start;

      .attendance-header-item {
        margin-left: 20px;
        line-height: 32px;
        position: relative;
        padding-left: 24px;
        color: #333333;
        cursor: pointer;
        font-size: 12px;

        .en-icon {
          position: absolute;
          top: 8px;
          left: 0;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .attendance-container {
    height: calc(100% - 50px);
    overflow-y: auto;
    padding: 20px 0 20px;

    .filter {
      display: flex;
      padding: 0 20px;
      justify-content: flex-start;

      .filter-item {
        display: flex;
        justify-content: flex-start;
        margin-right: 20px;

        .label {
          line-height: 32px;
          font-size: 12px;
          color: #333333;
          margin-right: 10px;
        }

        .content /deep/ {
          .el-date-editor {
            padding: 0 10px;
            height: 32px;
            line-height: 32px;

            .el-range-separator {
              font-size: 12px;
            }

            .el-range-input {
              font-size: 12px;
            }
          }
        }
      }
    }

    .charts-container {
      line-height: 32px;
      padding: 20px 20px 0 20px;
      height: 800px;

      .icon-toggle {
        margin-top: 6px;
      }

      .tool-bar {
        display: flex;
        justify-content: space-between;

        .icon-toggle {
          color: #3e90fe;
          cursor: pointer;
          transition: 0.3s ease-in-out all;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      .charts-content {
        width: 100%;
        height: 500px;
        margin: 30px auto 40px;
      }
    }

    .details-list {
      display: flex;
      padding: 20px 50px;
      margin-bottom: 16px;
      background-color: #f5f7fa;
      justify-content: space-between;

      .details-item {
        color: #3e90fe;
        text-align: center;

        .details-item-num {
          font-size: 14px;
          line-height: 14px;
          font-weight: bold;
          margin-bottom: 12px;
        }

        .details-item-label {
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }

  .full-screen-charts {
    background-color: #232c3d !important;
    position: relative;

    .full-charts {
      margin: 0 auto;
      width: calc(100% - 200px);
      height: calc(100% - 163px);

      .play-btn {
        /deep/ .en-icon {
          color: #ababab;
          cursor: pointer;
          font-size: 16px;
          margin: 0 12px;

          &.play-icon {
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>

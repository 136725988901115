<!--
 * @Author: 张家衡
 * @Date: 2021-01-29 15:54:45
 * @LastEditTime: 2021-11-11 10:30:59
 * @LastEditors: Please set LastEditors
 * @Description: 考勤查询 报表的 第二层穿透 打卡明细弹框
 * @FilePath: \user\src\views\hr\report\recordDetails.vue
-->
<template>
  <div>
    <en-dialog :visible="visiable" :close-on-click-modal="false" :modal="false" id="problem-z-index-important" @close="closeDialog" :append-to-body="true" width="1100px" style="text-aglin:left;">
      <div slot="title">
        <div class="title-box">
          <div>{{ title }}</div>
          <div>
            <en-icon style="margin-right:30px;color:#26C393;cursor: pointer;" title="导出" size="18px" fill="#26C393" name="daochu-danchuang" @click.native.stop="dataExport"></en-icon>
          </div>
        </div>
      </div>
      <div class="box" v-en-loading="loading">
        <en-table v-if="colums && colums.length && tableData && tableData.records" :data="tableData.records" :height="500" :page-config="{ pageModel: tableData, changeMethod: handlePageChanged }">
          <en-table-column type="index" width="80"></en-table-column>
          <en-table-column v-for="(item, index) in colums" :key="index" :prop="item.id" :label="item.name" :fieldType="item.colType" :data="item">
            <template v-slot="{ row, column }">
              <span class="pic-span" v-if="column.field == 'attachment' && row.attachment && row.attachment.length" @click.stop="toPic(row)">
                <!-- {{ row[column.field] }} -->
                <en-icon name="gaoqingtupian" size="18" style="color: #3e90fe;"></en-icon>{{ "(" + row.attachment.length + ")" }}
              </span>
              <span v-else-if="column.field == 'attachment' && row.attachment && row.attachment.length == 0">
                <!-- {{ row[column.field] }} -->
              </span>
              <span v-else>{{ row[column.field] }}</span>
            </template>
          </en-table-column>
        </en-table>
      </div>
    </en-dialog>
  </div>
</template>

<script>
import { hrService } from "@/api";
import { getFileUrl } from "@/tools/getFileUrl";

export default {
  name: "recordDetails",
  props: {
    // 公共数据
    params: {
      type: Object,
      default() {
        return {
          filterFields: ""
        };
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visiable: false,
      tableData: {},
      colums: [],
      loading: true,
      title: "打卡记录明细",
      attachment: []
    };
  },
  mounted() {
    this.init();
    this.visiable = this.show;
    console.log(this.params);
    this.attQueryRecordList({ page: 1, pageSize: 10 });
  },
  methods: {
    // 处理表头
    init() {
      const colums = [
        {
          sort: 0,
          filter: 0,
          fieldType: 1,
          field: "name",
          name: "姓名"
        },
        {
          sort: 0,
          filter: 0,
          fieldType: 1,
          field: "no",
          name: "工号"
        },
        {
          sort: 0,
          filter: 0,
          fieldType: 1,
          field: "deptName",
          name: "部门"
        },
        {
          sort: 0,
          filter: 0,
          fieldType: 7,
          field: "recordTime",
          name: "打卡时间",
          timeFormat: 9
        },
        {
          sort: 0,
          filter: 0,
          fieldType: 1,
          field: "address",
          name: "打卡地点"
        },
        {
          sort: 0,
          filter: 0,
          fieldType: 1,
          field: "isLegWorkName",
          name: "是否外勤"
        },
        {
          sort: 0,
          filter: 0,
          fieldType: 1,
          field: "remark",
          name: "备注"
        },
        {
          sort: 0,
          filter: 0,
          fieldType: 9,
          field: "attachment",
          name: "图片"
        },
        {
          sort: 0,
          filter: 0,
          fieldType: 1,
          field: "mac",
          name: "mac地址"
        },
        {
          sort: 0,
          filter: 0,
          fieldType: 1,
          field: "mac",
          name: "wifi名称"
        }
      ];
      this.colums = colums;
    },
    // 查询数据
    async attQueryRecordList(page) {
      this.loading = true;
      const p = { ...this.params, ...page };
      const res = await (hrService.attQueryRecordList(p) || {});
      this.tableData = res;
      this.loading = false;
    },
    // 分页事件
    handlePageChanged(obj) {
      this.queryAttDetailPageModel(obj);
    },
    toPic(row) {
      this.attachment = row.attachment;
      // 生成url
      this.attachment.forEach((element) => {
       element.url = getFileUrl(element.code, element.type, element.name);
      });
      this.$viewImage({
        datas: this.attachment,
        url: "url",
        thumUrl: "url",
        initialViewIndex: 0
      });
    },
    // 关闭回调
    closeDialog() {
      this.$emit("toShow", false);
    },
    // 导出
    async dataExport() {
      if (this.tableData.records && this.tableData.records.length) {
        // infoRecordId='+rowDat.id+'&day='+d
        console.log(this.params);
        const infoRecordId = JSON.parse(this.params.filterFields)[0].value;
        const p = {
          infoRecordId,
          day: this.params.day
        };
        const res = await hrService.attQueryRecordExport({ ...p, requestType: "stream" });
        const { headers } = res;
        const cd = headers["content-disposition"] || "";
        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/vnd.ms-excel" }));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.download = decodeURIComponent(cd.split("=")[1]);
        // document.body.appendChild(link);
        link.click();
        // document.body.removeChild(link);
      } else {
        this.$message({
          showClose: true,
          message: "当前无数据",
          type: "warning"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  margin: 0 auto;
  width: 100%;
  min-height: 550px;
  padding: 0 0 17px 0;
  font-size: 12px;
  line-height: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(99, 108, 120, 1);
  .pic-span {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #3e90fe;
  }
}
.title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vxe-table-box {
  /deep/.main-box {
    margin-right: 0 !important;
  }
}
</style>

<template>
  <div class="attendance-list">
    <en-title-card name="考勤汇总">
      <div slot="left">
        <en-icon
          name="fanhui"
          style="color: rgb(169, 181, 198)"
          size="small"
          class="attendance-list-back-icon"
          @click.native="backToAttendance"
        >
        </en-icon>
      </div>
      <div slot="right" class="attendance-list-right">
        <div v-if="showFilter" class="filter-item">
          <div class="label">
            考勤周期
          </div>
          <div class="content">
            <el-date-picker
              v-model="dateRange"
              :picker-options="pickerOptions"
              type="daterange"
              range-separator="––"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
            >
            </el-date-picker>
            <en-icon class="rili-icon" name="riqixuankuang" style="color: rgb(169, 181, 198)"></en-icon>
            <en-button class="search-button" @click="searchAction">查询</en-button>
          </div>
        </div>
        <en-icon @handleClick="showFilterClick" style="margin: 18px;color: rgb(169, 181, 198)" size="18px" name="sousuo"></en-icon>
      </div>

    </en-title-card>

    <div class="attendance-container">
      <div class="content-top">
        <div class="item"><div class="circle"></div><div class="title">应出勤: {{requiredDay}}天</div></div>
        <div class="item"><div class="circle"></div><div class="title">实出勤: {{actualDay}}天</div></div>
      </div>
      <div v-if="pieData.length > 0" class="charts-container">
        <en-charts
          v-if="refresh"
          :x-data="pieData"
          charts-type="pie"
          :need-show-pie-value="true"
          data-label="name"
          data-key="data"
          stack=""
          class="pie-chart"
          @chartsItemClick="chartsItemClick"
        >
        </en-charts>
      </div>
      <div class="table-container">
        <en-table
          border
          stripe
          :data="tableData"
          @cell-click="showDialogCick"
        >
          <en-table-column
            type="index"
            label="序号"
            width="60"
          >
          </en-table-column>
          <en-table-column
            prop="name"
            label="类型"
          >
          </en-table-column>
          <en-table-column
            prop="value"
            label="时长"
          >
          </en-table-column>
        </en-table>
      </div>
    </div>

    <attDetailTableDialog
      v-if="dialogShow"
      :dialog-visible.sync="dialogShow"
      :props-params="dialogParams"
    ></attDetailTableDialog>
  </div>
</template>

<script>
// import takeLeaveDetails from "./dialog/takeLeaveDetails";
import dayjs from "dayjs";
import attData from "../signInData";
import attDetailTableDialog from "../dialog/attDetailTableDialog";

export default {
  name: "AttendanceChart",
  components: { attDetailTableDialog },
  data() {
    return {
      dateRange: [dayjs(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`), new Date()],
      requiredDay: "0",
      actualDay: "0",
      pieData: [],
      refresh: true,
      showFilter: false,
      dialogShow: false,
      dialogParams: {},
      tableData: [
        {
          name: "正常",
          value: 1,
          type: 0
        }
      ],
      pickerOptions: {
        disabledDate: (time) => {
          const date = new Date();
          const start = new Date(date.getFullYear() - 1, 0, 1);
          const end = new Date(date.getFullYear() + 1, 0, 0);

          const startTime = start.getTime();
          const endTime = end.getTime();
          const myTime = new Date(time).getTime();

          if (myTime < startTime) {
            return true;
          }
          if (myTime > endTime) {
            return true;
          }
          return false;
        },
        shortcuts: [{
          text: "当天",
          onClick(picker) {
            const nowDate = new Date();
            picker.$emit("pick", [nowDate, nowDate]);
          }
        }, {
          text: "前一天",
          onClick(picker) {
            const nowDate = new Date();
            const start = new Date((nowDate / 1000 - 86400) * 1000);
            const end = new Date((nowDate / 1000 - 86400) * 1000);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "本周",
          onClick(picker) {
            const nowDate = new Date();
            const start = new Date(nowDate - (nowDate.getDay() - 1) * 86400000);
            const end = new Date((start / 1000 + 6 * 86400) * 1000);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "上周",
          onClick(picker) {
            const nowDate = new Date();
            const start = new Date(nowDate - (nowDate.getDay() + 6) * 86400000);
            const end = new Date((start / 1000 + 6 * 86400) * 1000);
            picker.$emit("pick", [start, end]);
          }
        },
          {
            text: "本月",
            onClick(picker) {
              const date = new Date();
              const start = new Date(date.getFullYear(), date.getMonth(), 1);
              const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
              picker.$emit("pick", [start, end]);
            }
          }, {
            text: "上月",
            onClick(picker) {
              const date = new Date();
              const start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
              const end = new Date(date.getFullYear(), date.getMonth(), 0);
              picker.$emit("pick", [start, end]);
            }
          }, {
            text: "本季",
            onClick(picker) {
              const date = new Date();
              let month = date.getMonth() + 1;
              if (month <= 3) {
                month = 1;
              } else if (month <= 6 && month > 3) {
                month = 4;
              } else if (month <= 9 && month > 6) {
                month = 7;
              } else if (month <= 12 && month > 9) {
                month = 10;
              }
              const start = new Date(date.getFullYear(), month - 1, 1);
              const end = new Date(date.getFullYear(), month + 2, 0);
              picker.$emit("pick", [start, end]);
            }
          }, {
            text: "上季",
            onClick(picker) {
              const date = new Date();
              let month = date.getMonth() + 1;
              if (month <= 3) {
                month = 1;
              } else if (month <= 6 && month > 3) {
                month = 4;
              } else if (month <= 9 && month > 6) {
                month = 7;
              } else if (month <= 12 && month > 9) {
                month = 10;
              }
              const start = new Date(date.getFullYear(), month - 4, 1);
              const end = new Date(date.getFullYear(), month - 1, 0);
              picker.$emit("pick", [start, end]);
            }
          }, {
            text: "本年",
            onClick(picker) {
              const date = new Date();
              let month = date.getMonth() + 1;
              if (month <= 3) {
                month = 1;
              } else if (month <= 6 && month > 3) {
                month = 4;
              } else if (month <= 9 && month > 6) {
                month = 7;
              } else if (month <= 12 && month > 9) {
                month = 10;
              }
              const start = new Date(date.getFullYear(), 0, 1);
              const end = new Date(date.getFullYear() + 1, 0, 0);
              picker.$emit("pick", [start, end]);
            }
          }, {
            text: "去年",
            onClick(picker) {
              const date = new Date();
              const start = new Date(date.getFullYear() - 1, 0, 1);
              const end = new Date(date.getFullYear(), 0, 0);
              picker.$emit("pick", [start, end]);
            }
          }, {
          text: "最近7天",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "最近30天",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          }
        }]
      }
    };
  },
  activated() {
    // this.attModelData = this.$route.params;
    // this.requestAttConfig();
    // this.updateDateRange();
    // this.requestUserPieData();
    // this.requestUserTableData();
    this.getDatas();
  },
  watch: {
    attPersonData() {
      this.handlePieData();
    },
    attPersonDetailData() {
      this.handleTableData();
    }
  },
  mixins: [attData],
  methods: {
    async getDatas() {
      this.attModelData = this.$route.params;
      await this.requestAttConfig();
      this.updateDateRange();
      this.requestUserPieData();
      this.requestUserTableData();
    },
    // 返回
    backToAttendance() {
      this.$router.go(-1);
    },
    // 查询
    searchAction() {
      if (!this.dateRange || this?.dateRange?.length < 2) {
        this.$message.error("请选择查询日期");
      }
      this.requestUserPieData();
      this.requestUserTableData();
    },
    showFilterClick() {
      this.showFilter = !this.showFilter;
    },
    updateDateRange() {
      const allStrategy = [];
      for (let i = 0; i < this.attModelData.attModel.length; i++) {
        const objModel = this.attModelData.attModel[i];
        if (objModel.strategy.length > 0) {
          allStrategy.push(...objModel.strategy);
        }
      }
      const allStartDays = [];
      const allEndDays = [];
      for (let i = 0; i < allStrategy.length; i++) {
        const obj = allStrategy[i];
        let startDate = null;
        let endDate = null;

        if (obj.cycleType === 1 || obj.cycleType === "1") {
          // 固定时间点
          console.log(obj);
          const startYear = new Date().getFullYear();
          let startMonth = new Date().getMonth();
          const startDay = obj.attStartDay > 1 ? obj.attStartDay : 1;

          const endYear = new Date().getFullYear();
          let endMonth = new Date().getMonth();
          const endDay = obj.attEndDay > 1 ? obj.attEndDay : 1;
          const today = new Date().getDate();
          if (today < startDay) {
            startMonth -= 1;
            endMonth -= 1;
          }
          if (obj.attEndDayType === 2) { // 次月
            endMonth += 1;
          }
          startDate = new Date(startYear, startMonth, startDay);
          endDate = new Date(endYear, endMonth, endDay);
        } else if (obj.cycleType === 2 || obj.cycleType === "2") {
          // 固定周期
          console.log(obj);
          startDate = new Date(obj.cycleStartDate);
          endDate = new Date((startDate / 1000 + obj.cycleDays * 86400) * 1000);
        }
        allStartDays.push(startDate);
        allStartDays.push(endDate);
      }
      console.log(allEndDays);
      console.log(allStartDays);
      allStartDays.sort((a, b) => Date.parse(a) - Date.parse(b));
      console.log(allStartDays);
      this.dateRange = [allStartDays[0], new Date()];
    },
    disabledDateFunction(time) {
      // 设置可选择的日期为今天之后的一个月内
      const curDate = (new Date()).getTime();
      // 这里算出一个月的毫秒数,这里使用30的平均值,实际中应根据具体的每个月有多少天计算
      const day = 30 * 24 * 3600 * 1000;
      const dateRegion = curDate + day;
      return time.getTime() < Date.now() - 8.64e7 || time.getTime() > dateRegion;

      // 设置选择的日期小于当前的日期,小于返回true,日期不可选
      // return time.getTime() < Date.now() - 8.64e7
    },
    // 请求饼图数据
    requestUserPieData() {
      if (this.dateRange?.length < 2) return;
      const obj = {
        day: `${dayjs(this.dateRange[0]).format("YYYY-MM-DD")},${dayjs(this.dateRange[1]).format("YYYY-MM-DD")}`,
        type: "0"
      };
      this.requestUserAttData(obj);
    },
    // 请求表格数据
    requestUserTableData() {
      if (this.dateRange?.length < 2) return;
      const obj = {
        day: `${dayjs(this.dateRange[0]).format("YYYY-MM-DD")},${dayjs(this.dateRange[1]).format("YYYY-MM-DD")}`
      };
      this.requestUserAttDetail(obj);
    },
    // 处理饼图数据
    handlePieData() {
      const list = [];
      // if (this.attPersonData?.normal > 0) {
        list.push({
          name: "正常",
          data: this.attPersonData.normal
        });
      // }
      // if (this.attPersonData?.late > 0) {
        list.push({
          name: "迟到",
          data: this.attPersonData.late
        });
      // }
      // if (this.attPersonData?.early > 0) {
        list.push({
          name: "早退",
          data: this.attPersonData.early
        });
      // }
      // if (this.attPersonData?.lack > 0) {
        list.push({
          name: "未打卡",
          data: this.attPersonData.lack
        });
      // }
      // if (this.attPersonData?.absent > 0) {
        list.push({
          name: "旷工",
          data: this.attPersonData.absent
        });
      // }
      // if (this.attPersonData?.abnormal > 0) {
        list.push({
          name: "异常",
          data: this.attPersonData.abnormal
        });
      // }
      // if (this.attPersonData?.lackCount > 0) {
        list.push({
          name: "缺勤",
          data: this.attPersonData.lackCount
        });
      // }
      // if (this.attPersonData?.leaveCount > 0) {
        list.push({
          name: "请假",
          data: this.attPersonData.leaveCount
        });
      // }
      // if (this.attPersonData?.tripCount > 0) {
        list.push({
          name: "出差",
          data: this.attPersonData.tripCount
        });
      // }
      // if (this.attPersonData?.outsideCount > 0) {
        list.push({
          name: "外出",
          data: this.attPersonData.outsideCount
        });
      // }
      this.refresh = false;
      this.$nextTick(() => {
        this.pieData = [...list];
        this.refresh = true;
      });
    },
    // 处理表格数据
    handleTableData() {
      const tableData = [];
      for (let i = 0; i < this.attPersonDetailData?.length; i++) {
        const obj = this.attPersonDetailData[i];
        tableData.push(obj);
        if (obj?.nameVariable === "mustAtt") {
          this.requiredDay = obj?.value;
        }
        if (obj?.nameVariable === "salatyDayAtt") {
          this.actualDay = obj?.value;
        }
      }
      this.tableData = [...tableData];
    },
    statusWithName(val) {
      let status = "";
      switch (val) {
        case "正常":
          status = "1";
          break;
        case "迟到":
          status = "2";
          break;
        case "早退":
          status = "3";
          break;
        case "未打卡":
          status = "5";
          break;
        case "异常":
          status = "11";
          break;
        case "旷工":
          status = "12";
          break;
        case "请假":
          status = "14";
          break;
        case "事假":
          status = "10";
          break;
        case "外出":
          status = "15";
          break;
        case "出差":
          status = "16";
          break;
        case "缺勤":
          status = "17";
          break;
        default:
          break;
      }
      return status;
    },
    chartsItemClick(val) {
      const name = val?.params?.name;
      const status = this.statusWithName(name);

      if (this.dateRange?.length < 2) return;

      const obj = {
        day: `${dayjs(this.dateRange[0]).format("YYYY-MM-DD")},${dayjs(this.dateRange[1]).format("YYYY-MM-DD")}`,
        status,
        title: name
      };
      this.dialogParams = obj;
      this.dialogShow = true;
    },
    showDialogCick(val) {
      if (val?.rowIndex < 1) {
        return;
      }
      if (val?.$columnIndex < 2) {
        return;
      }
      if (this.dateRange?.length < 2) return;

      let status = "";
      let title = "";
      if (val.rowIndex === 1) {
        status = "1,2,3,7,11,12,15,16";
        title = "实出勤";
      } else if (val.rowIndex === 2) {
        status = "17";
        title = "缺勤";
      } else if (val.rowIndex === 3) {
        status = "9";
        title = "加班";
      } else if (val.rowIndex === 4) {
        status = "2,3,5,11,12,17";
        title = "异常";
      } else if (val.rowIndex === 5) {
        status = "2";
        title = "迟到";
      } else if (val.rowIndex === 6) {
        status = "3";
        title = "早退";
      } else if (val.rowIndex === 7) {
        status = "5";
        title = "未打卡";
      } else if (val.rowIndex === 8) {
        status = "11";
        title = "异常";
      } else if (val.rowIndex === 9) {
        status = "12";
        title = "旷工";
      } else if (val.rowIndex === 10) {
        status = "16";
        title = "出差";
      } else if (val.rowIndex >= 11) {
        status = "10";
        title = "请假";
      }
      if (val.row.name.search("请假") !== -1) {
        status = "14";
      }

      let vacType = null;
      if (val.row?.vacId?.length > 0) {
        vacType = val.row.vacId;
      }

      const obj = {
        day: `${dayjs(this.dateRange[0]).format("YYYY-MM-DD")},${dayjs(this.dateRange[1]).format("YYYY-MM-DD")}`,
        status,
        title,
        vacType
      };
      this.dialogParams = obj;
      this.dialogShow = true;
    }
  }
};
</script>

<style lang="scss" scoped>
  .attendance-list {
    background-color: #fff;
    .en-title-card {
      position: relative;
      padding-left: 40px;

      .attendance-list-back-icon {
        cursor: pointer;
        position: absolute;
        left: 14px;
        top: 16px;
      }
    }

    .en-title-card {
      padding-right: 0;
      &/deep/.en-title-card-right-container {
        height: 50px;
        padding: 0;
      }
      .attendance-list-right {

        height: 50px;
        padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .filter-item {
          background-color: #E8ECF2;
          border-radius: 4px;
          height: 50px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .label {
            line-height: 32px;
            font-size: 12px;
            color: #333333;
            margin-left: 20px;
            margin-right: 10px;
          }

          .content {
            align-content: center;
            position: relative;
            /deep/.el-range-editor.el-input__inner{
              justify-content: center;
            }
            .rili-icon {
              position: absolute;
              top: 12px;
              left: 12px;
              width: 16px;
              height: 16px;
            }
          }

          .content /deep/ {
            .search-button {
              margin-left: 12px;
              margin-right: 20px;
              margin-bottom: 5px;
            }
            .el-date-editor {
              margin-top: 5px;
              padding: 0 10px;
              height: 32px;
              line-height: 32px;

              .el-range-separator {
                font-size: 12px;
              }

              .el-range-input {
                font-size: 12px;
              }

              .el-input__icon {
                visibility: hidden;
              }
            }
          }
        }

        .attendance-header-item {
          margin-left: 20px;
          line-height: 32px;
          position: relative;
          padding-left: 24px;
          color: #333333;
          cursor: pointer;
          font-size: 12px;

          .en-icon {
            position: absolute;
            top: 8px;
            left: 0;
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .attendance-container {
      height: calc(100% - 50px);
      overflow-y: auto;
      padding: 20px 0 20px;
      .content-top {
        display: flex;
        flex-direction: column;
        align-items: left;
        .item {
          height: 20px;
          display: flex;
          align-items: center;
          .circle {
            width:6px;
            height:6px;
            margin-right: 8px;
            margin-left: 20px;
            background:rgba(70,148,223,1);
            border-radius:50%;
          }
          .title {
            font-size:12px;
            font-weight:400;
            font-family:MicrosoftYaHei;
            color:rgba(99,108,120,1);
          }
        }
      }

      .charts-container {
        height: 460px;
        .pie-chart {
          height: 100%;
          width: 100%;
        }
      }

      .table-container {

      }

      .details-list {
        display: flex;
        padding: 20px 50px;
        margin-bottom: 16px;
        background-color: #f5f7fa;
        justify-content: space-between;

        .details-item {
          color: #3e90fe;
          text-align: center;

          .details-item-num {
            font-size: 14px;
            line-height: 14px;
            font-weight: bold;
            margin-bottom: 12px;
          }

          .details-item-label {
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
    }

  }
</style>

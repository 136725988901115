<template>
  <div class="over-time-container">
    <div class="head-contain">
      <div class="fanhui-contain" @click="backClick">
        <en-icon class="icon" name="fanhui" size="18px"></en-icon>
        <div>我的加班</div>
      </div>
    </div>

    <div class="body-contain">
<!--      无接口实现此功能-->
<!--      <div class="filter-contain">-->
<!--        <span class="title">查询年份</span>-->
<!--        <el-select v-model="year" placeholder="请选择">-->
<!--          <el-option-->
<!--            v-for="item in options"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value"-->
<!--          >-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--        <en-button class="button">-->
<!--          查询-->
<!--        </en-button>-->
<!--      </div>-->
<!--      <div class="bar-contain">-->
<!--        <overtimeChart :charts-data-list="dataList"></overtimeChart>-->
<!--        <div class="text">加班时长</div>-->
<!--      </div>-->
      <div class="table-contain">
        <en-table
          :data="dataSource.records"
        >
          <en-table-column type="index" label="序号" width="60">
            <template slot-scope="scope">
              <div>
                {{ holidayIndex(scope) }}
              </div>
            </template>
          </en-table-column>
          <en-table-column prop="createTime" label="提交时间" sortable>
            <template slot-scope="scope">
              <div>{{ scope.row.createTime.slice(0,16) }}</div>
            </template>
          </en-table-column>
          <en-table-column prop="overtimeTimeStart" label="开始时间">
            <template slot-scope="scope">
              <div>{{ scope.row.overtimeTimeStart.slice(0,16) }}</div>
            </template>
          </en-table-column>
          <en-table-column prop="overtimeTimeEnd" label="结束时间">
            <template slot-scope="scope">
              <div>{{ scope.row.overtimeTimeEnd.slice(0,16) }}</div>
            </template>
          </en-table-column>
          <en-table-column prop="overtimeLongTime" label="数量">
            <template slot-scope="scope">
              <div>{{ scope.row.overtimeLongTime + "h" }}</div>
            </template>
          </en-table-column>
          <en-table-column prop="code" label="单据编号"> </en-table-column>
        </en-table>
        <!-- overtimeTypeName remarks-->
      </div>
    </div>
    <en-pagination class="pagination" :page-model="dataSource" @change="changePage"></en-pagination>
  </div>
</template>

<script>
import { request } from "en-js";
import dayjs from "dayjs";
// import overtimeChart from "./overtimeChart";
import hrData from "./hrData";

export default {
  name: "MyOvertime",
  // components: {
  //   overtimeChart
  // },
  data() {
    return {
      dataSource: {},
      dataList: [],
      year: "2021",
      options: [
        {
          value: "2018",
          label: "2018"
        },
        {
          value: "2019",
          label: "2019"
        },
        {
          value: "2020",
          label: "2020"
        },
        {
          value: "2021",
          label: "2021"
        }
      ]
    };
  },
  watch: {
    dataSource(val) {
      console.log(val);
      this.updateChartsDataList();
    }
  },
  activated() {
    this.requestData();
  },
  mixins: [hrData],
  methods: {
    @request(true)
    async requestData() {
      const params = {
        pageNo: 1,
        pageSize: 10
      };
      this.dataSource = await this.requestMdMyWorkOverTimeInfo(params);
    },
    async changePage(val) {
      if (val) {
        const params = {
          pageNo: val.pageNo,
          pageSize: val.pageSize
        };
        this.dataSource = await this.requestMdMyWorkOverTimeInfo(params);
      }
    },
    holidayIndex(obj) {
      if (this.dataSource !== null) {
        return 1 + obj.rowIndex + (this.dataSource.pageNo - 1) * this.dataSource.pageSize;
      }
      return 1 + obj.rowIndex;
    },
    updateChartsDataList() {
      const dataList = [];
      const records = this.dataSource.records;
      for (let i = 0; i < records.length; i++) {
        const obj = records[i];
        const year = dayjs(obj.overtimeTimeEnd).year();
        if (`${year}` === this.year) {
          dataList.push(obj);
        }
      }
      this.dataList = [...dataList];
    },
    backClick() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.over-time-container {
  background: #FFFFFF;
  width: 100%;
  height: 100%;
  .head-contain {
    height: 49px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8ecf2;
    .fanhui-contain {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #333;
      .icon {
        margin-left: 15px;
        color: #a9b5c6;
      }
    }
  }
  .body-contain {
    width: 100%;
    height: calc(100% - 49px - 61px);
    overflow-y: auto;
    display: block;

    .filter-contain {
      width: 100%;
      height: 72px;
      display: flex;
      align-items: center;
      .title {
        padding: 0 12px 0 20px;
        font-size: 12px;
        font-weight: 400;
        color: #333;
      }
      .button {
        margin-left: 20px;
        width: 72px;
        height: 32px;
      }
    }
    .bar-contain {
      margin: 0 0 20px 0;
      width: 100%;
      height: 450px;
      position: relative;
      .bar_charts {
        width: 100%;
        height: 100%;
      }
      .text {
        position: absolute;
        left: 5px;
        bottom: 20px;
        height: 20px;
        font-size: 12px;
        text-align: left;
      }
    }
    .table-contain {
      width: 100%;
      height: 500px;
    }
  }
  .pagination {
    height: 60px;
    width: 100%;
    /*margin-top: 15px;*/
    border-top: 1px solid #e8ecf2;
  }
}
</style>

<!-- 考勤日历右侧打卡记录列表 -->
<template>
  <div v-click-outside="closePanel" class="attendance-details" :class="{ show: value }">
    <en-title-card name="日期"></en-title-card>
    <div class="date-detail">{{ dateDetail }}</div>
    <div class="time-list">
      <div v-for="item in list" :key="item.id" class="time-item">
        <div class="time-item-info" @click="showHandleAbnormalBill(item)">
          <div class="time-item-info-main">
            <div class="time-line-cover"></div>
            <div class="time-point"></div>
            <div class="time-info">
              {{ item.time }}
            </div>

            <div class="event-label">
              {{ item.label }}
            </div>
            <attStatus :status="item.status"></attStatus>
          </div>
          <div v-if="item.recordList" class="time-item-info-status" :class="{ dealFlag: item.infoRecord && item.infoRecord.dealEnableFlag === '1' && item.billStatusName === '未处理' }">
            {{ item.billStatusName !== '已处理' ? item.billStatusName : '' }}
            <!--BUG37585屏蔽已处理-->
          </div>
        </div>
        <div
          v-for="subItem in item.recordList"
          :key="subItem.id"
          class="time-item-container"
          @click="showRecordDetails(item,subItem)"
        >
          <div class="time-item-time">
            {{ subItem.time }}
          </div>
          <div class="time-item-name">
            {{ subItem.name }}
          </div>
        </div>
      </div>
    </div>

    <attDescriptionDialog
      v-if="showAttDetail"
      :dialog-visible.sync="showAttDetail"
      :signInfoData="signInfoData"
      @confirmWithInputValue="descriptionConfirmWithInputValue"
    ></attDescriptionDialog>

    <businessDialog
      v-if="businessDialog"
      :businessParams="businessParams"
      :dialogVisible.sync="businessDialog"
      @submitSuccess="submitSuccess">
    </businessDialog>

  </div>
</template>

<script>
import dayjs from "dayjs";
import businessDialog from "@/components/businessDetail/businessDialog";
import { newBusinessDialogParams, viewBusinessDialogParams } from "@/components/businessDetail/helper.js";
import attStatus from "./att-status";
import attDescriptionDialog from "./dialog/attDescriptionDialog";
import signInData from "./signInData";

export default {
  name: "AttendanceDetails",
  components: {
    attStatus,
    attDescriptionDialog,
    businessDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    dateInfo: {
      type: Object,
      default: () => ({})
    },
    attModel: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    dateInfo() {
      this.handleDataInfo();
    }
  },
  mixins: [signInData],
  data() {
    return {
      dateDetail: "",
      list: [
        {
          id: "1",
          time: "",
          label: "",
          status: 1,
          statusLabel: "",
          billStatus: "",
          billStatusName: "",
          recordList: [
            {
              id: "11",
              time: "",
              name: ""
            },
            {
              id: "12",
              time: "",
              name: ""
            }
          ]
        }
      ],
      dataSource: {
        recordInfo: {
          workDate: "",
          infoRecords: [
            {
              workTime: "",
              attType: 1,
              isDeal: 1,
              isDealName: "",
              bill: {
                billStatus: ""

              }
            }
          ]
        }
      },
      businessDialog: false,
      businessParams: {},
      showAbnormal: false,
      showAttDetail: false,
      signInfoData: {
        recordTime: "",
        ipAddress: "",
        wifiName: "",
        addrName: "",
        recordType: "",
        confirmFlag: "1",
        isModify: "1",
        isRemark: "1",
        isPicture: "1",
        remark: "",
        attachment: {},
        relations: {}
      }
    };
  },
  methods: {
    // 处理考勤数据
    handleDataInfo() {
      const infoRecords = this.dateInfo?.recordInfo?.infoRecords;
      if (infoRecords?.length === 1) {
        const item0 = infoRecords[0];
        this.dateDetail = `${this.dateInfo.recordInfo.workDate}  应打卡1次  ${dayjs(item0.workTime).format("HH:mm")}`;
      } else if (infoRecords?.length > 1) {
        const item0 = infoRecords[0];
        const item1 = infoRecords[infoRecords.length - 1];
        this.dateDetail = `${this.dateInfo.recordInfo.workDate}  应打卡${infoRecords.length}次  ${dayjs(item0.workTime).format("HH:mm")}-${dayjs(item1.workTime).format("HH:mm")}`;
      } else {
        this.dateDetail = `${this.dateInfo.dateString}   应打卡0次`;
      }

      this.list = [];
      for (let i = 0; i < infoRecords?.length; i++) {
        const item = infoRecords[i];
        const attObj = {};
        attObj.recordList = [];
        attObj.id = item.id;
        attObj.time = dayjs(item.workTime).format("HH:mm");
        attObj.label = "";
        if (item.attType === 1) {
          attObj.label = "上班";
        } else if (item.attType === 2) {
          attObj.label = "下班";
        } else if (item.attType === 3) {
          attObj.label = "加班开始";
        } else if (item.attType === 4) {
          attObj.label = "加班结束";
        } else if (item.attType === 5) {
          attObj.label = "外勤";
        } else if (item.attType === 6) {
          attObj.label = "请假开始";
        } else if (item.attType === 7) {
          attObj.label = "请假结束";
        }
        attObj.status = item.status;
        attObj.statusLabel = item.statusName;
        attObj.billStatusName = item.isDealName;
        attObj.infoRecord = item;
        if (item.bill && item.billStatus.length > 0) {
          if (item.bill.billStatus === "003" || item.bill.billStatus === "008") {
            attObj.billStatusName = item.isDealName;
          } else if (item.bill.billStatus === "000"
            || item.bill.billStatus === "001"
            || item.bill.billStatus === "002"
            || item.bill.billStatus === "004"
            || item.bill.billStatus === "006") {
            if (item.isDeal === 1) {
              if (item.bill.billStatus === "000") {
                attObj.billStatusName = "未提交";
              } else if (item.bill.billStatus === "001") {
                attObj.billStatusName = "待处理";
              } else if (item.bill.billStatus === "002") {
                attObj.billStatusName = "审批中";
              } else if (item.bill.billStatus === "004") {
                attObj.billStatusName = "已终止";
              } else if (item.bill.billStatus === "006") {
                attObj.billStatusName = "已审批";
              }
            } else {
              attObj.billStatusName = item.isDealName;
            }
          } else {
            attObj.billStatusName = "";
          }
        } else if (item.status === 2 || item.status === 3 || item.status === 5 || item.status === 11 || item.status === 12 || item.status === 17) {
          attObj.billStatusName = item.isDealName;
        } else {
          attObj.billStatusName = "";
        }
        const attRecords = [];
        for (let j = 0; j < item.attRecords?.length; j++) {
          attRecords.push(item.attRecords[j]);
        }
        for (let j = 0; j < attRecords.length; j++) {
          const subItem = attRecords[j];
          attObj.recordList.push({
            id: subItem.id,
            time: dayjs(subItem.recordTime).format("HH:mm"),
            name: subItem?.recordType === 1 ? subItem.addrName : (subItem?.recordType === 2 ? subItem.wifiName : subItem.ipAddress)
          });
        }
        this.list.push(attObj);
      }
    },
    // 关闭
    closePanel() {
      // this.$emit("input", false);
    },
    /**
       * @description 显示考勤异常处理弹窗
       */
    showHandleAbnormalBill(item) {
      console.log(item);
      if (item.infoRecord.bill === undefined) {
        if (item.infoRecord.status === 2
          || item.infoRecord.status === 3
          || item.infoRecord.status === 5
          || item.infoRecord.status === 11
          || item.infoRecord.status === 12
          || item.infoRecord.status === 17
          || item.infoRecord.status === 18) {
          if (item.infoRecord?.dealEnableFlag === "1" && item.billStatusName === "未处理") {
            this.$message("已超过异常处理时效，该异常无法处理");
            return;
          }
          this.businessDialogCick(true, item.infoRecord);
        }
      } else {
        this.businessDialogCick(false, item.infoRecord);
      }
    },
    /**
       * @description 显示考勤详情弹窗
       */
    showRecordDetails(item, subItem) {
      const infoRecords = this.dateInfo.recordInfo.infoRecords;
      for (let i = 0; i < infoRecords.length; i++) {
        const tmpItem = infoRecords[i];
        if (tmpItem.id === item.id) {
          for (let j = 0; j < tmpItem.attRecords?.length; j++) {
            const tmpSubItem = tmpItem.attRecords[j];
            if (tmpSubItem.id === subItem.id) {
              tmpSubItem.isDisplayRecord = true;
              this.signInfoData = tmpSubItem;
            }
          }
        }
      }
      this.showAttDetail = true;
    },
    // 确定弹框内容
    async descriptionConfirmWithInputValue(dataInfo) {
      this.signInfoData.id = dataInfo.id;
      this.signInfoData.remark = dataInfo.remark;
      this.signInfoData.attachment = [...dataInfo.picList];
      this.signInfoData.relations = [...dataInfo.projectList];
      const result = await this.requestUpdateSignIn(this.compareUpdateQueryData());
      if (result != null && result.result === "0") {
        this.$message.success("修改成功");
        this.$emit("reloadCurrentRecordData");
      } else {
        this.$message.error("修改失败");
      }
    },
    // 处理更新参数
    compareUpdateQueryData() {
      const params = {};
      params.id = this.signInfoData.id;
      params.remark = this.signInfoData.remark;
      const attachmentList = [];
      if (this.signInfoData.attachment) {
        for (let i = 0; i < this.signInfoData.attachment.length; i++) {
          const tmp = this.signInfoData.attachment[i];
          const obj = {
            name: tmp.name,
            suffix: tmp.extension || tmp.suffix,
            fileSize: tmp.size || tmp.fileSize,
            url: tmp.code || tmp.url,
            fileType: tmp.dataType || tmp.sts
          };
          attachmentList.push(obj);
        }
      }
      params.attachment = JSON.stringify(attachmentList);
      const relationList = [];
      if (this.signInfoData.relations) {
        for (let i = 0; i < this.signInfoData.relations.length; i++) {
          const tmp = this.signInfoData.relations[i];
          const obj = {
            objectTypeId: tmp.objectTypeId,
            dataId: tmp.selectValue.id,
            dataName: tmp.selectValue.name,
            objectDataType: tmp.dataType
          };
          relationList.push(obj);
        }
      }
      params.relations = JSON.stringify(relationList);
      return params;
    },

    // 跳转业务建模
    businessDialogCick(isNew, infoRecord) {
      // console.log(this.attModelData);
      let params = {
        templateId: this.attModel.formId,
        businessType: this.attModel.formType
      };
      if (isNew) {
        params = Object.assign(params, { attRecordId: infoRecord.id });
        this.businessParams = newBusinessDialogParams(params);
      } else {
        params = Object.assign(params, { id: infoRecord?.bill?.id, attRecordId: infoRecord.id });
        this.businessParams = viewBusinessDialogParams(params);
      }
      this.businessDialog = true;
    },
    // 提交成功回调
    submitSuccess() {
      console.log("异常处理成功");
      this.businessDialog = false;
      this.$emit("reloadRequestRecordData");
    }
  }
};
</script>

<style lang="scss">
  .attendance-details {
    width: 385px;
    margin-left: 10px;
    transition: 0.5s ease-in-out right;
    position: absolute;
    right: -385px;
    background-color: #ffffff;
    height: 100%;
    top: 0;
    -webkit-border-radius: 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px;
    border-radius: 4px 4px 4px;
    overflow-y: auto;

    &.show {
      right: 0;
    }

    .date-detail {
      text-align: left;
      padding: 18px 18px 0;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      color: #333;
    }

    .time-list {
      width: calc(100% - 40px);
      margin: 20px 20px 20px;
      position: relative;
      border-left: 2px solid #e8e8e8;
      border-top-left-radius: 10px;

      .time-item {
        width: 100%;
        padding-left: 20px;
        position: relative;
        flex-wrap: wrap;
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 20px;
        color: #333333;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        .time-line-cover {
          background-color: white;
          width: 12px;
          height: 10px;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          border-radius: 100%;
          position: absolute;
          left: -5px;
          top: 0;
          display: none;
        }
        &:nth-child(1) {
          .time-line-cover {
            display: block;
          }
        }

        .time-item-info {
          display: flex;
          justify-content: space-between;
          height: 12px;

          .time-item-info-main {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .time-point {
              width: 8px;
              height: 8px;
              background-color: #3e90fe;
              -webkit-border-radius: 100%;
              -moz-border-radius: 100%;
              border-radius: 100%;
              position: absolute;
              left: -5px;
            }

            .time-info {
              margin-right: 10px;
            }

            .event-label {
              margin-right: 10px;
            }

            .event-status {
              &.error {
                color: #f76b6b;
              }
            }
          }

          .time-item-info-status {
            color: #3e90fe;
            cursor: pointer;
            &.dealFlag {
              color: #333333;
            }
          }
        }

        .time-item-container {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          font-size: 12px;
          line-height: 12px;
          color: #333333;
          padding: 11px 12px;
          background-color: #f5f7fa;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          border-radius: 4px;
          margin-top: 15px;

          .time-item-time {
            margin-right: 10px;
          }
        }
      }
    }
  }
</style>

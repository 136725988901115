var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closePanel,
          expression: "closePanel",
        },
      ],
      staticClass: "attendance-details",
      class: { show: _vm.value },
    },
    [
      _c("en-title-card", { attrs: { name: "日期" } }),
      _c("div", { staticClass: "date-detail" }, [
        _vm._v(_vm._s(_vm.dateDetail)),
      ]),
      _c(
        "div",
        { staticClass: "time-list" },
        _vm._l(_vm.list, function (item) {
          return _c(
            "div",
            { key: item.id, staticClass: "time-item" },
            [
              _c(
                "div",
                {
                  staticClass: "time-item-info",
                  on: {
                    click: function ($event) {
                      return _vm.showHandleAbnormalBill(item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "time-item-info-main" },
                    [
                      _c("div", { staticClass: "time-line-cover" }),
                      _c("div", { staticClass: "time-point" }),
                      _c("div", { staticClass: "time-info" }, [
                        _vm._v(" " + _vm._s(item.time) + " "),
                      ]),
                      _c("div", { staticClass: "event-label" }, [
                        _vm._v(" " + _vm._s(item.label) + " "),
                      ]),
                      _c("attStatus", { attrs: { status: item.status } }),
                    ],
                    1
                  ),
                  item.recordList
                    ? _c(
                        "div",
                        {
                          staticClass: "time-item-info-status",
                          class: {
                            dealFlag:
                              item.infoRecord &&
                              item.infoRecord.dealEnableFlag === "1" &&
                              item.billStatusName === "未处理",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.billStatusName !== "已处理"
                                  ? item.billStatusName
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._l(item.recordList, function (subItem) {
                return _c(
                  "div",
                  {
                    key: subItem.id,
                    staticClass: "time-item-container",
                    on: {
                      click: function ($event) {
                        return _vm.showRecordDetails(item, subItem)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "time-item-time" }, [
                      _vm._v(" " + _vm._s(subItem.time) + " "),
                    ]),
                    _c("div", { staticClass: "time-item-name" }, [
                      _vm._v(" " + _vm._s(subItem.name) + " "),
                    ]),
                  ]
                )
              }),
            ],
            2
          )
        }),
        0
      ),
      _vm.showAttDetail
        ? _c("attDescriptionDialog", {
            attrs: {
              "dialog-visible": _vm.showAttDetail,
              signInfoData: _vm.signInfoData,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.showAttDetail = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.showAttDetail = $event
              },
              confirmWithInputValue: _vm.descriptionConfirmWithInputValue,
            },
          })
        : _vm._e(),
      _vm.businessDialog
        ? _c("businessDialog", {
            attrs: {
              businessParams: _vm.businessParams,
              dialogVisible: _vm.businessDialog,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.businessDialog = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.businessDialog = $event
              },
              submitSuccess: _vm.submitSuccess,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
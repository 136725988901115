var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "attendance-list" },
    [
      _c("en-title-card", { attrs: { name: "考勤查询" } }, [
        _c(
          "div",
          { attrs: { slot: "left" }, slot: "left" },
          [
            _c("en-icon", {
              staticClass: "attendance-list-back-icon",
              attrs: { name: "fanhui", size: "small" },
              nativeOn: {
                click: function ($event) {
                  return _vm.backToAttendance.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "attendance-list-right",
            attrs: { slot: "right" },
            slot: "right",
          },
          [
            _c(
              "div",
              {
                staticClass: "attendance-header-item",
                on: { click: _vm.showFullCharts },
              },
              [
                _c("en-icon", {
                  attrs: { name: "quanping", color: "#FFAD2C" },
                }),
                _vm._v(" 全屏 "),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "attendance-header-item" },
              [
                _c("en-icon", {
                  attrs: { name: "daochu-kuaijiecaozuo", color: "#26C393" },
                }),
                _vm._v(" 导出 "),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "attendance-container" },
        [
          _c(
            "div",
            { staticClass: "filter" },
            [
              _c("div", { staticClass: "filter-item" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 考勤日期 ")]),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      model: {
                        value: _vm.filterInfo.dateRange,
                        callback: function ($$v) {
                          _vm.$set(_vm.filterInfo, "dateRange", $$v)
                        },
                        expression: "filterInfo.dateRange",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "filter-item" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 范围 ")]),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.filterInfo.range,
                          callback: function ($$v) {
                            _vm.$set(_vm.filterInfo, "range", $$v)
                          },
                          expression: "filterInfo.range",
                        },
                      },
                      _vm._l(_vm.rangeList, function (item) {
                        return _c(
                          "el-option",
                          {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("en-button", { staticClass: "filter-item" }, [
                _vm._v(" 查询 "),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "charts-container" }, [
            _c(
              "div",
              { staticClass: "tool-bar" },
              [
                _c("en-button-list", {
                  attrs: { list: _vm.chartsList },
                  on: { change: _vm.setChartsInfo },
                  model: {
                    value: _vm.activeCharts,
                    callback: function ($$v) {
                      _vm.activeCharts = $$v
                    },
                    expression: "activeCharts",
                  },
                }),
                _c("en-icon", {
                  staticClass: "icon-toggle",
                  attrs: { name: "qitafenlei", size: "18" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.showDetails.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", {
              staticClass: "charts-content",
              attrs: { id: "chartsContainer" },
            }),
          ]),
          _c(
            "div",
            { staticClass: "details-list" },
            _vm._l(_vm.detailList, function (item, index) {
              return _c("div", { key: index, staticClass: "details-item" }, [
                _c("div", { staticClass: "details-item-num" }, [
                  _vm._v(" " + _vm._s(item.num) + " "),
                ]),
                _c("div", { staticClass: "details-item-label" }, [
                  _vm._v(" " + _vm._s(item.label) + " "),
                ]),
              ])
            }),
            0
          ),
          _c(
            "en-table",
            {
              attrs: {
                data: _vm.tableData,
                "tree-config": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
                border: "",
              },
            },
            [
              _c("en-table-column", {
                attrs: { prop: "dept", label: "部门", width: "180" },
              }),
              _c("en-table-column", {
                attrs: {
                  prop: "shouldAttendNum",
                  label: "应出勤（人）",
                  width: "180",
                },
              }),
              _c("en-table-column", {
                attrs: { prop: "attendanceNum", label: "实出勤（人）" },
              }),
              _c("en-table-column", {
                attrs: { prop: "absenceNum", label: "缺勤（人）" },
              }),
              _c("en-table-column", {
                attrs: { prop: "takeLeaveNum", label: "请假（人）" },
              }),
              _c("en-table-column", {
                attrs: { prop: "travelNum", label: "出差（人）" },
              }),
              _c("en-table-column", {
                attrs: { prop: "overTimeNum", label: "加班（人）" },
              }),
              _c("en-table-column", {
                attrs: { prop: "outSideNum", label: "外出（人）" },
              }),
              _c("en-table-column", {
                attrs: { prop: "abnormalPersonalNum", label: "异常人次" },
              }),
              _c("en-table-column", {
                attrs: { prop: "uncheckedNum", label: "未打卡（次）" },
              }),
              _c("en-table-column", {
                attrs: { prop: "lateNum", label: "迟到（次）" },
              }),
              _c("en-table-column", {
                attrs: { prop: "leaveEarlyNum", label: "早退（次）" },
              }),
              _c("en-table-column", {
                attrs: { prop: "absenteeismNum", label: "旷工（次）" },
              }),
              _c("en-table-column", {
                attrs: { prop: "abnormalNum", label: "异常（次）" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "fullscreen",
        {
          ref: "fullscreen",
          class: { "full-screen-charts": _vm.showFullScreen },
          attrs: { fullscreen: _vm.showFullScreen },
          on: {
            "update:fullscreen": function ($event) {
              _vm.showFullScreen = $event
            },
          },
        },
        [
          _vm.showFullScreen
            ? _c(
                "en-top-menu",
                { attrs: { divider: `true`, "menu-name": "考勤报表名称" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "en-top-menu-right",
                      attrs: { slot: "right" },
                      on: {
                        click: function ($event) {
                          _vm.showFullScreen = false
                        },
                      },
                      slot: "right",
                    },
                    [
                      _c("en-icon", {
                        attrs: { name: "tuichuquanping", size: "small" },
                      }),
                      _vm._v(" 退出全屏 "),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c("div", {
            staticClass: "full-charts",
            attrs: { id: "fullCharts" },
          }),
          _vm.showFullScreen
            ? _c(
                "div",
                { staticClass: "play-btn" },
                [
                  _c("en-icon", {
                    attrs: { name: "xiayiye1", color: "#ABABAB" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.changeCharts("prev")
                      },
                    },
                  }),
                  _c("en-icon", {
                    staticClass: "play-icon",
                    attrs: {
                      name: _vm.play ? "bofang-kanban" : "zanting-kanban",
                      color: "#ffffff",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.togglePlayStatus.apply(null, arguments)
                      },
                    },
                  }),
                  _c("en-icon", {
                    attrs: { name: "xiayiye", color: "#ABABAB" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.changeCharts("next")
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showDetailsDialog
        ? _c("take-leave-details", {
            attrs: { dialogVisible: _vm.showDetailsDialog },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.showDetailsDialog = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.showDetailsDialog = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
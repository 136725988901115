<template>
  <div class="hr-config-list">
    <div class="content">
      <div class="header">
        人事信息
      </div>
      <div class="content-detail">
        <en-card
          v-for="el in cardItems"
          :key="el.type"
          class="list-item"
          :icon-name="el.icon"
          :icon-bg="el.color"
          icon-color="#fff"
          :edit-able="false"
          :title="el.name"
          :data-info="el"
          @click.native="handleItemClick(el)"
        >
        </en-card>
        <!-- <en-card
          v-for="(item2, index2) in item.templateList"
          :key="index2"
          class="card"
          :icon-name="item2.wfModel === '000' ? 'zidingyiliu' : 'gudingliu'"
          :icon-bg="item2.wfModel === '000' ? '#3e90fe' : '#25C393'"
          icon-color="#ffffff"
          :title="item2.name"
          :sub-title="item2.wfModel === '000' ? '自有流' : '固定流'"
          :edit-able="true"
          :menu-list="getMenuList(item2.enableFlag)"
          menu-key="id"
          menu-label="name"
          :data-info="item2"
          @click.native="tempClick(item2)"
          :disabled="item2.enableFlag !== 0"
          @change="menuClick"
        >
        </en-card> -->
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "HrConfigList",
  data() {
    return {
      cardItems: [
        {
          name: "我的假期",
          type: 1,
          icon: "wodejiaqi",
          color: "#3e90fe"
        },
        {
          name: "我的加班",
          type: 2,
          icon: "wodejiaban",
          color: "#26C393"
        }
        // {
        //   name: "报表",
        //   type: 4,
        //   icon: "jiaqileixing-HRguanli",
        //   color: "#26C393"
        // }
      ]
    };
  },
  methods: {
    handleItemClick(el) {
      switch (el.type) {
        case 1:
          this.$router.push("/hr/myHoliday");
          break;
        case 2:
          this.$router.push("/hr/myOvertime");
          break;
        case 3:
          this.$router.push("/hr/attendance");
          break;
        case 4:
          this.$router.push("/hr/chartDetail");
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.hr-config-list {
  .content {
    padding: 0;
    height: 100%;
    background-color: #1d2532;
    font-size: 12px;
    .header {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      background-color: #fff;
      font-size: 14px;
      height: 49px;
      @include seperator-line;
      line-height: 49px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      text-align: left;
      padding-left: 20px;
    }
    .content-detail {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      height: calc(100% - 50px);
      background-color: #fff;
      overflow-y: auto;
      padding-left: 20px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .en-card:hover {
        background-color: #F5F9FD;
      }
      .list-title {
        text-align: left;
        width: 100%;
        line-height: 32px;
        height: 32px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }
      .list-item {
        margin: 20px 16px 20px 0;
        & /deep/ .en-card-normal-main-title {
          line-height: 34px;
          margin-top: 0;
        }
      }
      .home-att {
        width: 340px;
        height: 300px;
      }
    }
  }
}
</style>

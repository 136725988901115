<template>
  <div>
    <el-dialog
      title=""
      class="my-dialog"
      :show-close="false"
      :visible.sync="dialogLogVisible"
    >
      <div class="body-contain">
        <span v-if="propsParams.status === 9 || propsParams.status === '9'" class="title">{{ "加班明细" }}</span>
        <span v-else-if="propsParams.status === 10 || propsParams.status === '10' || propsParams.status === 14 || propsParams.status === '14'" class="title">{{ "请假明细" }}</span>
        <span v-else class="title">{{ propsParams.title + "考勤明细" }}</span>
        <div @click="closeClick">
          <en-icon
            name="guanbi-danchuang"
            class="close-icon"
            size="14px"
            :style="{fill:'#A9B5C6'}"
          ></en-icon>
        </div>
        <div class="table-contain">
          <en-table
            v-if="propsParams.status === 9 || propsParams.status === '9'"
            :data="dataSource.records"
            height="511"
            @cell-click="businessDialogCick"
            @filter-sort-change="handleFilterSortChange"
          >
            <en-table-column
              type="index"
              label="序号"
              width="60"
            >
              <template slot-scope="scope">
                <div>
                  {{ myIndex(scope) }}
                </div>
              </template>
            </en-table-column>
            <en-table-column
              prop="name"
              label="姓名"
              width="100"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="deptName"
              label="部门"
              width="120"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="overtimeType"
              label="加班类型"
              width="120"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="overTimeSectionTime"
              label="加班时间"
              width="240"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
              <template slot-scope="scope">
                <div>
                  {{ overTimeDescription(scope.row) }}
                </div>
              </template>
            </en-table-column>
            <en-table-column
              prop="overTimeWhenLong"
              label="加班时长"
              width="120"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row.overTimeWhenLong + 'h' }}
                </div>
              </template>
            </en-table-column>
            <en-table-column
              prop="remark"
              label="备注"
              width="140"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
          </en-table>

          <en-table
            v-else-if="propsParams.status === 10 || propsParams.status === '10' || propsParams.status === 14 || propsParams.status === '14'"
            :data="dataSource.records"
            height="511"
            @cell-click="businessDialogCick"
            @filter-sort-change="handleFilterSortChange"
          >
            <en-table-column
              type="index"
              label="序号"
              width="60"
            >
              <template slot-scope="scope">
                <div>
                  {{ myIndex(scope) }}
                </div>
              </template>
            </en-table-column>
            <en-table-column
              prop="name"
              label="姓名"
              width="100"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="deptName"
              label="部门"
              width="120"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="vacName"
              label="请假类型"
              width="120"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="leaveSectionTime"
              label="请假时间"
              width="240"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
              <template slot-scope="scope">
                <div>
                  {{ leaveTimeDescription(scope.row) }}
                </div>
              </template>
            </en-table-column>
            <en-table-column
              prop="leaveWhenLong"
              label="请假时长"
              width="120"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row.leaveWhenLong + 'h' }}
                </div>
              </template>
            </en-table-column>
            <en-table-column
              prop="remark"
              label="备注"
              width="140"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
          </en-table>

          <en-table
            v-else-if="propsParams.status === 16 || propsParams.status === '16'"
            :data="dataSource.records"
            height="511"
            @cell-click="businessDialogCick"
            @filter-sort-change="handleFilterSortChange"
          >
            <en-table-column
              type="index"
              label="序号"
              width="60"
            >
              <template slot-scope="scope">
                <div>
                  {{ myIndex(scope) }}
                </div>
              </template>
            </en-table-column>
            <en-table-column
              prop="name"
              label="姓名"
              width="100"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="deptName"
              label="部门"
              width="120"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="vacName"
              label="出差类型"
              width="120"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.tripTimes > 0 ? "出差" : "销出差" }}</div>
              </template>
            </en-table-column>
            <en-table-column
              prop="tripStartDate"
              label="出差时间"
              width="240"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
              <template slot-scope="scope">
                <div>
                  {{ tripTimeDescription(scope.row) }}
                </div>
              </template>
            </en-table-column>
            <en-table-column
              prop="tripTimes"
              label="出差时长"
              width="120"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row.tripTimes + 'h' }}
                </div>
              </template>
            </en-table-column>
            <en-table-column
              prop="remark"
              label="备注"
              width="140"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
          </en-table>

          <en-table
            v-else
            :data="dataSource.records"
            height="511"
            @cell-click="businessDialogCick"
            @filter-sort-change="handleFilterSortChange"
          >
            <en-table-column
              type="index"
              label="序号"
              width="60"
            >
              <template slot-scope="scope">
                <div>
                  {{ myIndex(scope) }}
                </div>
              </template>
            </en-table-column>
            <en-table-column
              prop="name"
              label="姓名"
              width="140"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="no"
              label="工号"
              width="140"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="deptName"
              label="部门"
              width="140"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="workTime"
              label="应打卡时间"
              width="140"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="recordTime"
              label="实际打卡时间"
              width="140"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="address"
              label="地点"
              width="140"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="ipAddress"
              label="ip地址"
              width="140"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="wifiName"
              label="wifi名称"
              width="140"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="remark"
              label="备注"
              width="140"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="statusName"
              label="考勤结果"
              width="140"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
            <en-table-column
              prop="isDealName"
              label="处理状态"
              width="140"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
              <template slot-scope="scope">
                <div :style="{color:hasBill(scope.row)?'#3e90fe':'#000000'}">
                  {{ dealDescription(scope.row) }}
                </div>
              </template>
            </en-table-column>
          </en-table>
        </div>

        <en-pagination class="pagination" :page-model="dataSource" @change="changePage"></en-pagination>
      </div>
    </el-dialog>

    <businessDialog
      v-if="businessDialog"
      :businessParams="businessParams"
      :dialogVisible.sync="businessDialog">
    </businessDialog>
  </div>
</template>

<script>
  import { request } from "en-js";
  import { hrService } from "@/api";
  import businessDialog from "@/components/businessDetail/businessDialog";
  import { viewBusinessDialogParams } from "@/components/businessDetail/helper.js";
  import Store from "@/store";

  export default {
    name: "attDetailTableDialog",
    props: {
      dialogVisible: {
        type: Boolean,
        default: true
      },
      propsParams: {
        type: Object,
        default() {
          return {
            day: "",
            status: "",
            title: ""
          };
        }
      }
    },
    components: {
      businessDialog
    },
    data() {
      return {
        dialogLogVisible: true,
        businessDialog: false,
        businessParams: {},
        dataSource: {},
        filterParams: {},
        requestParams: {}
      };
    },
    watch: {
      dialogLogVisible(val) {
        this.$emit("update:dialogVisible", val);
      }
    },
    mounted() {
      this.requestData();
    },
    methods: {
      @request(true)
      async requestData() {
        let myBusinessType = 1;
        let myQueryAttendanceType = "";
        if (this.propsParams.status === 9 || this.propsParams.status === "9") {
          myBusinessType = 3;
          myQueryAttendanceType = 1;
        }
        if (this.propsParams.status === 10 || this.propsParams.status === "10"
          || this.propsParams.status === 14 || this.propsParams.status === "14") {
          myBusinessType = 2;
          myQueryAttendanceType = 1;
        }
        if (this.propsParams.status === 16 || this.propsParams.status === "16") {
          myBusinessType = 4;
          myQueryAttendanceType = 1;
        }
        const params = {
          userId: Store.getters.userInfo.userId,
          status: this.propsParams.status,
          day: this.propsParams.day,
          businessType: myBusinessType,
          queryAttendanceType: myQueryAttendanceType,
          type: 1,
          pageSize: 10,
          pageNo: 1,
          vacType: this.propsParams.vacType
        };
        this.requestParams = {
          businessType: myBusinessType,
          queryAttendanceType: myQueryAttendanceType,
          vacType: this.propsParams.vacType
        };
        this.dataSource = await hrService.queryAttDetailPageModelList(params);
      },
      async changePage(val) {
        if (val) {
          const params = {
            pageNo: val.pageNo,
            pageSize: val.pageSize,
            filterFields: this.filterParams?.filterFields,
            sortField: this.filterParams?.sortField,
            userId: Store.getters.userInfo.userId,
            day: this.propsParams.day,
            status: this.propsParams.status,
            businessType: this.requestParams.businessType,
            queryAttendanceType: this.requestParams.queryAttendanceType,
            vacType: this.requestParams.vacType,
            type: 1
          };
          this.dataSource = await hrService.queryAttDetailPageModelList(params);
        }
      },
      async requestDataWithFilter() {
        const params = {
          pageNo: 1,
          pageSize: 10,
          filterFields: this.filterParams?.filterFields,
          sortField: this.filterParams?.sortField,
          day: this.propsParams.day,
          status: this.propsParams.status,
          userId: Store.getters.userInfo.userId,
          businessType: this.requestParams.businessType,
          queryAttendanceType: this.requestParams.queryAttendanceType,
          vacType: this.requestParams.vacType,
          type: 1
        };
        this.dataSource = await hrService.queryAttDetailPageModelList(params);
      },
      myIndex(obj) {
        if (this.dataSource !== null) {
          return 1 + obj.rowIndex + (this.dataSource.pageNo - 1) * this.dataSource.pageSize;
        }
        return 1 + obj.rowIndex;
      },
      overTimeDescription(obj) {
        const timeDesc = obj.overTimeSectionTime;
        const timeArray = timeDesc.split(",");
        if (timeArray.length < 2) {
          return timeDesc;
        }
        return `${timeArray[0].substring(0, 16) }至${ timeArray[1].substring(0, 16)}`;
      },
      leaveTimeDescription(obj) {
        const timeDesc = obj.leaveSectionTime;
        const timeArray = timeDesc.split(",");
        if (timeArray.length < 2) {
          return timeDesc;
        }
        return `${timeArray[0].substring(0, 16) }至${ timeArray[1].substring(0, 16)}`;
      },
      tripTimeDescription(obj) {
        return `${obj.tripStartDate}至${ obj.tripEndDate}`;
      },
      holidayDescription(obj) {
        let descriptionText = "+";
        let hours = parseFloat(obj.vacHours);
        if (hours < 0) {
          descriptionText = "-";
          hours = -hours;
        }

        if (!obj.conTime) {
          return `${descriptionText + hours}h`;
        }
        const conTime = parseFloat(obj.conTime);
        const day = parseInt(hours / conTime, 10);
        const hour = Math.round(parseFloat(hours % conTime) * 100) / 100;

        if (day !== 0) {
          descriptionText = `${descriptionText + day}d`;
        }
        if (hour !== 0) {
          descriptionText = `${descriptionText + hour}h`;
        }
        if (day === 0 && hour === 0) {
          descriptionText = "0h";
        }
        return descriptionText;
      },
      closeClick() {
        this.dialogLogVisible = false;
      },
      confirmClick() {
        this.$notify({
          type: "success",
          title: "温馨提示",
          message: "您选择了"
        });
      },
      // 跳转业务建模
      businessDialogCick(val) {
        if (val?.$columnIndex < 11) {
          return;
        }
        const businessKey = val?.row?.bill?.id;
        if (businessKey?.length > 0) {
          let params = {
            templateId: businessKey,
            id: businessKey
          };
          params = Object.assign(params, { id: businessKey });
          this.businessParams = viewBusinessDialogParams(params);
          this.businessDialog = true;
        }
      },
      // 筛选
      handleFilterSortChange({ filterData, sortData }) {
        this.filterParams = {
          filterFields: filterData,
          sortField: sortData
        };
        this.requestDataWithFilter();
      },
      hasBill(obj) {
        if (obj?.bill !== null && obj?.bill !== undefined) return true;
        return false;
      },
      dealDescription(obj) {
        if (obj?.bill !== null) {
          if (obj.bill?.billStatus === "003" || obj.bill?.billStatus === "008") { // 已结束,已提交
            return obj.isDealName;
          }
          if (obj?.isDeal === "1" || obj?.isDeal === 1) {
            if (obj.bill?.billStatus === "000") {
              return "未提交";
            }
            if (obj.bill?.billStatus === "001") {
              return "待处理";
            }
            if (obj.bill?.billStatus === "002") {
              return "审批中";
            }
            if (obj.bill?.billStatus === "004") {
              return "已终止";
            }
            if (obj.bill?.billStatus === "006") {
              return "已审批";
            }
          }
        }
        if (obj.status === 1
          || obj.status === 7
          || obj.status === 14
          || obj.status === 15
          || obj.status === 16) {
          return "";
        }
        return obj.isDealName;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .body-contain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 1100px;
    min-height: 635px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 4px;

    .title {
      position: absolute;
      left: 20px;
      top: 20px;
      height: 14px;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      text-align: left;
      color: rgba(51, 51, 51, 1);
    }
    .close-icon {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 14px;
      height: 14px;
    }
    .table-contain {
      /*border: 1px solid #dce5ec;*/
      margin: 52px 20px 15px 20px;
      min-height: 511px;
      /*overflow-y: auto;*/
    }
    .pagination {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
  .my-dialog {
    & /deep/ .el-dialog__header {
      padding: 0;
      margin: 0;
      height: 0;
    }
    & /deep/ .el-dialog__body {
      padding: 0;
      margin: 0;
    }
  }

</style>

<template>
  <div class="over-time-container">
    <div class="head-contain">
      <div class="fanhui-contain" @click="backClick">
        <en-icon class="icon" name="fanhui" size="18px"></en-icon>
        <div>我的报表</div>
      </div>
    </div>

    <div class="body-contain">
      <div class="bar-contain">
        <en-vertical-bar></en-vertical-bar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyOvertime",
  data() {
    return {
      holidayLogShow: false,
      holidayDetailShow: false,
      year: "",
      options: [
        {
          value: "2018",
          label: "2018"
        },
        {
          value: "2019",
          label: "2019"
        },
        {
          value: "2020",
          label: "2020"
        }
      ],
      tableData: [],
      pageModel: {
        pageNo: 5,
        pageSize: 10,
        totalPages: 50,
        totalCount: 500
      },
      chartData: {
        columns: ["日期", "访问用户", "下单用户", "下单率"],
        rows: [
          {
            日期: "1/1", 访问用户: 1393, 下单用户: 1093, 下单率: 0.32
          },
          {
            日期: "1/2", 访问用户: 3530, 下单用户: 3230, 下单率: 0.26
          },
          {
            日期: "1/3", 访问用户: 2923, 下单用户: 2623, 下单率: 0.76
          },
          {
            日期: "1/4", 访问用户: 1723, 下单用户: 1423, 下单率: 0.49
          },
          {
            日期: "1/5", 访问用户: 3792, 下单用户: 3492, 下单率: 0.323
          },
          {
            日期: "1/6", 访问用户: 4593, 下单用户: 4293, 下单率: 0.78
          }
        ]
      },
      chartSettings: { type: "line" },
      chartsDataX: {
        title: "",
        yData: [
          {
            data: [135, 122, 97, 75, 106, 83, 91, 135, 122, 97, 75, 106]
          }
        ],
        xData: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月"
        ]
      }
    };
  },
  mounted() {
    for (let i = 1; i < 11; i++) {
      const obj = {
        submitTime: "2018-12-12 10:30",
        startTime: "2018-12-22 12:30",
        endTime: "2018-12-30 11:30",
        number: i * 3,
        no: `B2019${i}`
      };
      this.tableData.push(obj);
    }
  },
  methods: {
    backClick() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.over-time-container {
  width: 100%;
  height: 100%;
  .head-contain {
    height: 49px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8ecf2;
    .fanhui-contain {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      color: #333;
      .icon {
        margin-left: 15px;
        color: #a9b5c6;
      }
    }
  }
  .body-contain {
    width: 100%;
    height: calc(100% - 49px);
    overflow-y: auto;
    display: block;

    .filter-contain {
      width: 100%;
      height: 72px;
      display: flex;
      align-items: center;
      .title {
        padding: 0 12px 0 20px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #333;
      }
      .button {
        margin-left: 20px;
        width: 72px;
        height: 32px;
      }
    }
    .bar-contain {
      margin: 0 0 20px 0;
      width: 100%;
      height: 500px;
      .bar_charts {
        width: 100%;
        height: 100%;
      }
    }
    .table-contain {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;

      .table-contain {
        //border: 1px solid #e8ecf2;
        height: auto;
      }
    }
    .pagination {
      height: 60px;
      width: 100%;
    }
  }
}
</style>

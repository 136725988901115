<!--考勤打卡状态-->
<template>
  <div
    class="en-att-status"
    :class="{
      abnormal:
        status === 2 ||
        status === 3 ||
        status === 4 ||
        status === 5 ||
        status === 11 ||
        status === 12 ||
        status === 17
    }"
  >
    {{ statusName }}
  </div>
</template>

<script>
export default {
  name: "EnAttStatus",
  props: {
    // 状态码
    status: Number
  },
  data() {
    return {};
  },
  computed: {
    statusName() {
      let name = "";
      const statusValue = Number(this.status);
      switch (statusValue) {
        case 1:
        case 7:
          name = "正常";
          break;
        case 2:
          name = "迟到";
          break;
        case 3:
          name = "早退";
          break;
        case 4:
          name = "脱岗";
          break;
        case 5:
          name = "未打卡";
          break;
        case 11:
          name = "异常";
          break;
        case 12:
          name = "旷工";
          break;
        case 14:
          name = "请假";
          break;
        case 15:
          name = "外出";
          break;
        case 16:
          name = "出差";
          break;
        case 17:
          name = "缺勤";
          break;
        case 18:
          name = "加班";
          break;
        default:
          break;
      }
      return name;
    }
  }
};
</script>

<style lang="scss" scoped>
.en-att-status {
  // background-color: rgba(185, 196, 210, 0.08);
  display: inline-block;
  height: 12px;
  padding: 0 10px;
  line-height: 12px;
  font-size: 12px;
  color: #b9c4d2;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  // border-color: #ff000000;
  // border-width: 1px;
  // border-style: solid;
  color: #333;
  &.abnormal {
    color: #f76b6b;
  }
}
</style>

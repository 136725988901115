var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "my-dialog",
          attrs: {
            title: "",
            "show-close": false,
            visible: _vm.dialogLogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "body-contain" },
            [
              _vm.propsParams.status === 9 || _vm.propsParams.status === "9"
                ? _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s("加班明细")),
                  ])
                : _vm.propsParams.status === 10 ||
                  _vm.propsParams.status === "10" ||
                  _vm.propsParams.status === 14 ||
                  _vm.propsParams.status === "14"
                ? _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s("请假明细")),
                  ])
                : _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.propsParams.title + "考勤明细")),
                  ]),
              _c(
                "div",
                { on: { click: _vm.closeClick } },
                [
                  _c("en-icon", {
                    staticClass: "close-icon",
                    style: { fill: "#A9B5C6" },
                    attrs: { name: "guanbi-danchuang", size: "14px" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "table-contain" },
                [
                  _vm.propsParams.status === 9 || _vm.propsParams.status === "9"
                    ? _c(
                        "en-table",
                        {
                          attrs: {
                            data: _vm.dataSource.records,
                            height: "511",
                          },
                          on: {
                            "cell-click": _vm.businessDialogCick,
                            "filter-sort-change": _vm.handleFilterSortChange,
                          },
                        },
                        [
                          _c("en-table-column", {
                            attrs: {
                              type: "index",
                              label: "序号",
                              width: "60",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          " " + _vm._s(_vm.myIndex(scope)) + " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3971335824
                            ),
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "name",
                              label: "姓名",
                              width: "100",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "deptName",
                              label: "部门",
                              width: "120",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "overtimeType",
                              label: "加班类型",
                              width: "120",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "overTimeSectionTime",
                              label: "加班时间",
                              width: "240",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.overTimeDescription(scope.row)
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              23547395
                            ),
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "overTimeWhenLong",
                              label: "加班时长",
                              width: "120",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.overTimeWhenLong + "h"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1620865783
                            ),
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "remark",
                              label: "备注",
                              width: "140",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.propsParams.status === 10 ||
                      _vm.propsParams.status === "10" ||
                      _vm.propsParams.status === 14 ||
                      _vm.propsParams.status === "14"
                    ? _c(
                        "en-table",
                        {
                          attrs: {
                            data: _vm.dataSource.records,
                            height: "511",
                          },
                          on: {
                            "cell-click": _vm.businessDialogCick,
                            "filter-sort-change": _vm.handleFilterSortChange,
                          },
                        },
                        [
                          _c("en-table-column", {
                            attrs: {
                              type: "index",
                              label: "序号",
                              width: "60",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        " " + _vm._s(_vm.myIndex(scope)) + " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "name",
                              label: "姓名",
                              width: "100",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "deptName",
                              label: "部门",
                              width: "120",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "vacName",
                              label: "请假类型",
                              width: "120",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "leaveSectionTime",
                              label: "请假时间",
                              width: "240",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.leaveTimeDescription(scope.row)
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "leaveWhenLong",
                              label: "请假时长",
                              width: "120",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.leaveWhenLong + "h"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "remark",
                              label: "备注",
                              width: "140",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.propsParams.status === 16 ||
                      _vm.propsParams.status === "16"
                    ? _c(
                        "en-table",
                        {
                          attrs: {
                            data: _vm.dataSource.records,
                            height: "511",
                          },
                          on: {
                            "cell-click": _vm.businessDialogCick,
                            "filter-sort-change": _vm.handleFilterSortChange,
                          },
                        },
                        [
                          _c("en-table-column", {
                            attrs: {
                              type: "index",
                              label: "序号",
                              width: "60",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        " " + _vm._s(_vm.myIndex(scope)) + " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "name",
                              label: "姓名",
                              width: "100",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "deptName",
                              label: "部门",
                              width: "120",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "vacName",
                              label: "出差类型",
                              width: "120",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.tripTimes > 0
                                            ? "出差"
                                            : "销出差"
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "tripStartDate",
                              label: "出差时间",
                              width: "240",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.tripTimeDescription(scope.row)
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "tripTimes",
                              label: "出差时长",
                              width: "120",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row.tripTimes + "h") +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "remark",
                              label: "备注",
                              width: "140",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "en-table",
                        {
                          attrs: {
                            data: _vm.dataSource.records,
                            height: "511",
                          },
                          on: {
                            "cell-click": _vm.businessDialogCick,
                            "filter-sort-change": _vm.handleFilterSortChange,
                          },
                        },
                        [
                          _c("en-table-column", {
                            attrs: {
                              type: "index",
                              label: "序号",
                              width: "60",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        " " + _vm._s(_vm.myIndex(scope)) + " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "name",
                              label: "姓名",
                              width: "140",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "no",
                              label: "工号",
                              width: "140",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "deptName",
                              label: "部门",
                              width: "140",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "workTime",
                              label: "应打卡时间",
                              width: "140",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "recordTime",
                              label: "实际打卡时间",
                              width: "140",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "address",
                              label: "地点",
                              width: "140",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "ipAddress",
                              label: "ip地址",
                              width: "140",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "wifiName",
                              label: "wifi名称",
                              width: "140",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "remark",
                              label: "备注",
                              width: "140",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "statusName",
                              label: "考勤结果",
                              width: "140",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                          }),
                          _c("en-table-column", {
                            attrs: {
                              prop: "isDealName",
                              label: "处理状态",
                              width: "140",
                              sortable: "",
                              "filter-sort-config": { sortable: true },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        style: {
                                          color: _vm.hasBill(scope.row)
                                            ? "#3e90fe"
                                            : "#000000",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.dealDescription(scope.row)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c("en-pagination", {
                staticClass: "pagination",
                attrs: { "page-model": _vm.dataSource },
                on: { change: _vm.changePage },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.businessDialog
        ? _c("businessDialog", {
            attrs: {
              businessParams: _vm.businessParams,
              dialogVisible: _vm.businessDialog,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.businessDialog = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.businessDialog = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
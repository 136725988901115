<!--环形图-->
<template>
  <div class="pie-doughnut-contain">
    <div
      id="enPieDoughnut_123"
      ref="enPieDoughnut"
      class="en-pie-doughnut"
    >
    </div>
    <div v-if="mDataList.length > 0" class="center-contain">
      <p class="center-num">
        {{ totalValue }}
      </p>
      <div class="center-title">
        总余额
      </div>
    </div>
  </div>
</template>

<script>
// 参数详解
// https://www.cnblogs.com/1996zy/p/8963385.html
export default {
  name: "EnPieDoughnut",
  props: {
    chartsData: {
      type: Array
    }
  },
  data() {
    return {
      totalValue: "",
      mDataList: [],
      colorList: [
        "#72ade9",
        "#ffdb5c",
        "#4ed3c3",
        "#f35d5e",
        "#ffbf9a",
        "#8378eb",
        "#4b58be",
        "#6be3eb",
        "#fe8463",
        "#fdbe50",
        "#72c8f2",
        "#f4b1cf",
        "#80adc5",
        "#4d8cae",
        "#b6d0de",
        "#89a0d3",
        "#3fb27e",
        "#51b3f1",
        "#8378eb",
        "#eae38c"
      ]
    };
  },
  watch: {
    chartsData(val) {
      if (val) {
        this.mDataList = val;
        let totalHours = 0;
        for (let i = 0; i < this.mDataList.length; i++) {
          const obj = this.mDataList[i];
          totalHours += obj.value;
        }
        const hours = parseFloat(totalHours);
        const conTime = parseFloat(this.mDataList[0].conTime);
        const day = parseInt(hours / conTime, 10);
        const hour = Math.round(parseFloat(hours % conTime) * 100) / 100;
        let descriptionText = "";
        if (day > 0) {
          descriptionText = `${day}d`;
        }
        if (hour > 0) {
          descriptionText = `${descriptionText + hour}h`;
        }
        if (day <= 0 && hour <= 0) {
          descriptionText = "0h";
        }

        this.totalValue = descriptionText;
        this.setDomInfo();
        this.$forceUpdate();
      }
    }
  },
  // watch: {
  //   chartsData: {
  //     deep: true,
  //     hanlder() {
  //       this.setChartsInfo();
  //     }
  //   }
  // },
  // watch: {
  //   screenWidth(val){
  //   // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
  //     if(!this.timer){
  //       // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
  //       this.screenWidth = val;
  //       this.timer = true;
  //       let that = this;
  //       setTimeout(function(){
  //         // 打印screenWidth变化的值
  //         console.log(that.screenWidth);
  //         that.timer = false;
  //         that.resizeCharts();
  //       },500);
  //     }
  //   }
  // },
  mounted() {
    this.$nextTick(() => {
      this.setDomInfo();
      const myChart = document.getElementById(this.$refs.enPieDoughnut.id);
      if (!myChart) {
        return;
      }
      const thisChart = this.$charts.init(myChart);
      window.addEventListener("resize", () => {
        thisChart.resize(); // 页面大小变化后Echarts也更改大小
      });
    });
    // const that = this;
    // window.onresize = () => {
    //   return (() => {
    //     window.screenWidth = document.body.clientWidth;
    //     that.screenWidth = window.screenWidth;
    //   })();
    // };
  },
  methods: {
    // 设置图表的ID信息=>为拖拽时，动态重绘图表做准备
    setDomInfo() {
      this.$refs.enPieDoughnut.id = `enPieDoughnut${new Date().getTime()}`;
      console.log(`Charts_id:${this.$refs.enPieDoughnut.id}`);
      this.setChartsInfo();
    },
    // 绘制图表
    setChartsInfo() {
      const _self = this;
      const myChart = document.getElementById(this.$refs.enPieDoughnut.id);
      if (!myChart) {
        return;
      }
      const charts = _self.$charts.init(myChart);
      charts.setOption({
        tooltip: {
          trigger: "item",
          textStyle: {
            fontSize: "14",
            fontWeight: "normal"
          },
          formatter: (params) => `<div style='color: #fff; text-align: left;'>
               ${params.data.name} : ${params.data.descriptionText}</div>(占比 : ${params.percent}%)`
        },
        // 色块色值
        color: _self.colorList,
        series: [
          {
            type: "pie",
            // center : [ "50%", "44%" ],
            radius: ["30%", "70%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: true,
                position: "outOfBrush",
                textStyle: {
                  fontSize: "14",
                  fontWeight: "bold"
                },
                // eslint-disable-next-line no-useless-concat
                formatter: (params) => `${params.data.descriptionText}` + `\n${params.data.name}`
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "18",
                  fontWeight: "bold"
                }
              }
            },
            labelLine: { normal: { show: true } },
            data: _self.mDataList,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ],
        animation: true
        // 标题
        // title: {
        //   text: "环形图",
        //   subtext: "副标题",
        //   x: "left",                 // 水平安放位置，默认为左对齐，可选为：
        //   // 'center' ¦ 'left' ¦ 'right'
        //   // ¦ {number}（x坐标，单位px）
        //   y: "top",                  // 垂直安放位置，默认为全图顶端，可选为：
        //   // 'top' ¦ 'bottom' ¦ 'center'
        //   // ¦ {number}（y坐标，单位px）
        //   //textAlign: null          // 水平对齐方式，默认根据x设置自动调整
        //   backgroundColor: "rgba(200,0,0,1)",
        //   borderColor: "#ccc",       // 标题边框颜色
        //   borderWidth: 1,            // 标题边框线宽，单位px，默认为0（无边框）
        //   padding: 5,                // 标题内边距，单位px，默认各方向内边距为5，
        //   // 接受数组分别设定上右下左边距，同css
        //   itemGap: 10,               // 主副标题纵向间隔，单位px，默认为10，
        //   textStyle: {
        //     fontSize: 18,
        //     fontWeight: "bolder",
        //     color: "#333"          // 主标题文字颜色
        //   },
        //   subtextStyle: {
        //     fontSize: 14,
        //     color: "#aaa",          // 副标题文字颜色
        //   }
        // },
        // 图例
        // legend: {
        //   orient: "vertical",
        //   x: "right",
        //   orient: "horizontal",//布局方式，默认为水平布局，可选为：'horizontal' ¦ 'vertical'
        //   x: "center",// 水平安放位置，默认为全图居中，可选为：'center' ¦ 'left' ¦ 'right' ¦ {number}（x坐标，单位px）
        //   y: "top",// 垂直安放位置，默认为全图顶端，可选为：'top' ¦ 'bottom' ¦ 'center'¦ {number}（y坐标，单位px）
        //   backgroundColor: "rgba(0,0,0,0)",
        //   borderColor: "#ccc",       // 图例边框颜色
        //   borderWidth: 0,            // 图例边框线宽，单位px，默认为0（无边框）
        //   padding: 5,                // 图例内边距，单位px，默认各方向内边距为5，
        //   // 接受数组分别设定上右下左边距，同css
        //   itemGap: 10,               // 各个item之间的间隔，单位px，默认为10，
        //   // 横向布局时为水平间隔，纵向布局时为纵向间隔
        //   itemWidth: 20,             // 图例图形宽度
        //   itemHeight: 14,            // 图例图形高度
        //   textStyle: {
        //     fontSize: 14,
        //     color: "#333"         // 图例文字颜色
        //   },
        // },
      });
      charts.on("click", (param) => {
        _self.$emit("enPieDoughnutDidClick", param);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .pie-doughnut-contain {
    position: relative;
    width: 100%;
    height: 100%;
    .en-pie-doughnut {
      // width: 100%;
      // height: 100%;
      // position: relative;
      width: 700px;
      height: 550px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .center-contain {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100px;
      height: 100px;
      transform: translate(-50%, -50%);
      .center-num {
        margin: 26px 0 0 0;
        height: 24px;
        font-size: 24px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #033333;
        text-align: center;
      }
      .center-title {
        margin-top: 10px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        text-align: center;
        color: #033333;
      }
    }
  }
</style>

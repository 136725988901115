var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "over-time-container" }, [
    _c("div", { staticClass: "head-contain" }, [
      _c(
        "div",
        { staticClass: "fanhui-contain", on: { click: _vm.backClick } },
        [
          _c("en-icon", {
            staticClass: "icon",
            attrs: { name: "fanhui", size: "18px" },
          }),
          _c("div", [_vm._v("我的报表")]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "body-contain" }, [
      _c("div", { staticClass: "bar-contain" }, [_c("en-vertical-bar")], 1),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="dialog-contain">
    <el-dialog
      title=""
      :show-close="false"
      :visible.sync="dialogVisible"
    >
      <div class="body-contain">
        <span class="title">假期详情</span>
        <div @click="closeClick">
          <en-icon
            name="guanbi-danchuang"
            class="close-icon"
            size="14px"
            :style="{fill:'#A9B5C6'}"
          ></en-icon>
        </div>
        <div class="table-contain">
          <en-table
            border
            stripe
            :data="tableData"
          >
            <en-table-column
              prop="number"
              label="数量"
            >
            </en-table-column>
            <en-table-column
              prop="date"
              label="有效期至"
            >
            </en-table-column>
          </en-table>
        </div>

        <div class="bottom-text">
          合计:5d 6h
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "EnHolidayDetailDialog",
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return { tableData: [] };
  },
  watch: {
    dialogVisible(val) {
      this.$emit("update:dialogVisible", val);
    }
  },
  mounted() {
    for (let i = 0; i < 18; i++) {
      const obj = {
        number: i,
        date: "2020-05-01"
      };
      this.tableData.push(obj);
    }
  },
  methods: {
    closeClick() {
      this.dialogVisible = false;
    },
    confirmClick() {
      this.$notify({
        type: "success",
        title: "温馨提示",
        message: "您选择了"
      });
      // this.holidayLogVisible = false;
      // this.$emit("getTripOccupyValue", this.selectData);
    }
  }
};
</script>

<style lang="scss" scoped>
.body-contain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 1100px;
  height: 626px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 4px;

  .title {
    position: absolute;
    left: 20px;
    top: 20px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 14px;
    height: 14px;
  }
  .table-contain {
    // border: 1px solid #dce5ec;
    margin: 52px 20px 5px 20px;
    height: 507px;
    overflow-y: auto;
  }
  .bottom-text {
    position: absolute;
    left: 20px;
    bottom: 26px;
    color: #333;
  }
}
.dialog-contain {
  & /deep/ .el-dialog__header {
    padding: 0;
    margin: 0;
    height: 0;
  }
  & /deep/ .el-dialog__body {
    padding: 0;
    margin: 0;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "attendance-page" },
    [
      _c(
        "div",
        {
          staticClass: "attendance-page-main",
          class: { "with-details": _vm.showAttendanceDetails },
        },
        [
          _c("div", { staticClass: "attendance-title" }, [
            _c(
              "div",
              { staticClass: "attendance-title-left" },
              [
                _c("en-icon", {
                  staticClass: "back-icon",
                  attrs: {
                    name: "iconfanhuishangcengji",
                    size: "18px",
                    color: "#A9B5C6",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.goBack.apply(null, arguments)
                    },
                  },
                }),
                _c("div", { staticClass: "label" }, [_vm._v(" 考勤详情 ")]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "attendance-title-right",
                on: { click: _vm.showAttendanceList },
              },
              [
                _c("en-icon", { attrs: { name: "bianjilishi", size: "14" } }),
                _c("div", { staticClass: "label" }, [_vm._v(" 统计 ")]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "attendance-header" },
            [
              _c("en-icon", {
                attrs: { color: "#A9B5C6", name: "xiayiye1", size: "10" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.changeMonth(-1)
                  },
                },
              }),
              _c("en-icon", {
                staticClass: "dateicon",
                attrs: {
                  color: "#A9B5C6",
                  name: "iconriqixuankuang",
                  size: "16",
                },
              }),
              _c("div", { staticClass: "attendance-month" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.dateInfo.year + "年" + _vm.dateInfo.month) +
                    " "
                ),
              ]),
              _c("en-icon", {
                attrs: { color: "#A9B5C6", name: "xiayiye", size: "10" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.changeMonth.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "attendance-week-list" },
            _vm._l(_vm.weekArr, function (item) {
              return _c(
                "div",
                { key: item, staticClass: "attendance-week-item" },
                [_vm._v(" " + _vm._s(item) + " ")]
              )
            }),
            0
          ),
          _c("div", { staticClass: "attendance-daily-list-contain" }, [
            _c(
              "div",
              { staticClass: "attendance-daily-list" },
              _vm._l(_vm.calendarDataList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.timeStamp,
                    staticClass: "attendance-daily-item",
                    class: {
                      "other-month": item.isOtherMonth,
                      "week-day": item.weekday === 6 || item.weekday === 0,
                      "to-day": item.timeStamp === _vm.dateInfo.timeStamp,
                      worked: item.timeStamp < _vm.dateInfo.timeStamp,
                      selected: item.timeStamp === _vm.selectedDate.timeStamp,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.showDetails(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "attendance-daily-item-label" }, [
                      _vm._v(" " + _vm._s(item.date) + " "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "attendance-daily-status-contain" },
                      [
                        _vm._l(item.statusList, function (subItem) {
                          return _c(
                            "div",
                            {
                              key: subItem.id,
                              staticClass: "attendance-daily-status-name",
                              class: {
                                late:
                                  subItem.status === 2 || subItem.status === 3,
                                abnormal:
                                  subItem.status === 11 ||
                                  subItem.status === 12,
                                travel:
                                  subItem.status === 15 ||
                                  subItem.status === 16,
                                askLeave: subItem.status === 14,
                                overtime: subItem.status === 18,
                              },
                            },
                            [_vm._v(" " + _vm._s(subItem.statusName) + " ")]
                          )
                        }),
                        !item.isShowDetail &&
                        (item.type === 1 || item.type === 3)
                          ? _c("div", {
                              staticClass: "attendance-daily-status-circle",
                              class: {
                                abnormal: item.type === 3,
                                normal: item.type === 1,
                              },
                            })
                          : _vm._e(),
                        item.isShowSchedule && item.scheduleDetail
                          ? _c(
                              "div",
                              { staticClass: "attendance-daily-item-type" },
                              [_vm._v(" " + _vm._s(item.scheduleDetail) + " ")]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      ),
      _c("attendance-details", {
        attrs: { "date-info": _vm.selectedDate, "att-model": _vm.attModelData },
        on: {
          reloadRequestRecordData: _vm.reloadRequestRecordData,
          reloadCurrentRecordData: _vm.reloadCurrentRecordData,
        },
        model: {
          value: _vm.showAttendanceDetails,
          callback: function ($$v) {
            _vm.showAttendanceDetails = $$v
          },
          expression: "showAttendanceDetails",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-contain" },
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "", "show-close": false, visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "body-contain" }, [
            _c("span", { staticClass: "title" }, [_vm._v("假期详情")]),
            _c(
              "div",
              { on: { click: _vm.closeClick } },
              [
                _c("en-icon", {
                  staticClass: "close-icon",
                  style: { fill: "#A9B5C6" },
                  attrs: { name: "guanbi-danchuang", size: "14px" },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table-contain" },
              [
                _c(
                  "en-table",
                  { attrs: { border: "", stripe: "", data: _vm.tableData } },
                  [
                    _c("en-table-column", {
                      attrs: { prop: "number", label: "数量" },
                    }),
                    _c("en-table-column", {
                      attrs: { prop: "date", label: "有效期至" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "bottom-text" }, [_vm._v(" 合计:5d 6h ")]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
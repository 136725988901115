var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hr-config-list" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "header" }, [_vm._v(" 人事信息 ")]),
      _c(
        "div",
        { staticClass: "content-detail" },
        _vm._l(_vm.cardItems, function (el) {
          return _c("en-card", {
            key: el.type,
            staticClass: "list-item",
            attrs: {
              "icon-name": el.icon,
              "icon-bg": el.color,
              "icon-color": "#fff",
              "edit-able": false,
              title: el.name,
              "data-info": el,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.handleItemClick(el)
              },
            },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <el-dialog
      title=""
      class="my-dialog"
      :show-close="false"
      :visible.sync="dialogLogVisible"
    >
      <div class="body-contain">
        <span class="title">假期日志</span>
        <div @click="closeClick">
          <en-icon
            name="guanbi-danchuang"
            class="close-icon"
            size="14px"
            :style="{fill:'#A9B5C6'}"
          ></en-icon>
        </div>
        <div class="table-contain">
          <en-table
            :data="dataSource.records"
            height="511"
            @cell-click="businessDialogCick"
            @filter-sort-change="handleFilterSortChange"
          >
            <en-table-column
              type="index"
              label="序号"
              width="60"
            >
              <template slot-scope="scope">
                <div>
                  {{ holidayIndex(scope) }}
                </div>
              </template>
            </en-table-column>
            <en-table-column
              prop="createTime"
              label="操作时间"
              width="170"
              sortable
              :filter-sort-config="{filterable:true, sortable:true}"
              :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}">
            </en-table-column>
            <en-table-column
              prop="modeOfName"
              label="增减方式"
              width="120"
              sortable
              :filter-sort-config="{filterable:true}"
              :filter-render="{name: 'ElInput', attrs: {placeholder: '请输入增减方式...'}}"
            >
            </en-table-column>
            <en-table-column
              prop="vacName"
              label="假期类型"
              width="100"
              sortable
              :filter-sort-config="{filterable:true}"
              :filter-render="{name: 'ElInput', attrs: {placeholder: '请输入假期类型...'}}"
            >
            </en-table-column>
            <en-table-column
              prop="vacHours"
              label="数量"
              width="100"
              sortable
            >
              <template slot-scope="scope">
                <div>
                  {{ holidayDescription(scope.row) }}
                </div>
              </template>
            </en-table-column>
            <en-table-column
              prop="remark"
              label="备注"
              sortable
              :filter-sort-config="{ sortable:true }"
            >
            </en-table-column>
          </en-table>
        </div>

        <en-pagination class="pagination" :page-model="dataSource" @change="changePage"></en-pagination>
      </div>
    </el-dialog>

    <businessDialog
      v-if="businessDialog"
      :businessParams="businessParams"
      :hideCollect="hideCollect"
      :dialogVisible.sync="businessDialog">
    </businessDialog>
  </div>
</template>

<script>
import { request, componentMixins } from "en-js";
import businessDialog from "@/components/businessDetail/businessDialog";
import { viewBusinessDialogParams } from "@/components/businessDetail/helper.js";
import hrData from "./hrData";
// import attStatus from "../attendance/att-status";
// import attDescriptionDialog from "../attendance/dialog/attDescriptionDialog";

export default {
  name: "EnTripOccupyDialog",
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    }
  },
  components: {
    businessDialog
  },
  data() {
    return {
      dialogLogVisible: true,
      businessDialog: false,
      businessParams: {},
      dataSource: {},
      filterParams: {},
      hideCollect: true
    };
  },
  mixins: [hrData, componentMixins],
  watch: {
    dialogLogVisible(val) {
      this.$emit("update:dialogVisible", val);
    }
  },
  mounted() {
    this.requestData();
  },
  methods: {
    @request(true)
    async requestData() {
      const params = {
        pageNo: 1,
        pageSize: 10
      };
      this.dataSource = await this.requestMyHolidayRecords(params);
    },
    async changePage(val) {
      if (val) {
        const params = {
          pageNo: val.pageNo,
          pageSize: val.pageSize,
          filterFields: this.filterParams?.filterFields,
          sortField: this.filterParams?.sortField
        };
        this.dataSource = await this.requestMyHolidayRecords(params);
      }
    },
    async requestDataWithFilter() {
      const params = {
        pageNo: 1,
        pageSize: 10,
        filterFields: this.filterParams?.filterFields,
        sortField: this.filterParams?.sortField
      };
      this.dataSource = await this.requestMyHolidayRecords(params);
    },
    holidayIndex(obj) {
      if (this.dataSource !== null) {
        return 1 + obj.rowIndex + (this.dataSource.pageNo - 1) * this.dataSource.pageSize;
      }
      return 1 + obj.rowIndex;
    },
    holidayDescription(obj) {
      let descriptionText = "+";
      let hours = parseFloat(obj.vacHours);
      if (hours < 0) {
        descriptionText = "-";
        hours = -hours;
      }

      if (!obj.conTime) {
        return `${descriptionText + hours}h`;
      }
      const conTime = parseFloat(obj.conTime);
      const day = parseInt(hours / conTime, 10);
      const hour = Math.round(parseFloat(hours % conTime) * 100) / 100;

      if (day !== 0) {
        descriptionText = `${descriptionText + day}d`;
      }
      if (hour !== 0) {
        descriptionText = `${descriptionText + hour}h`;
      }
      if (day === 0 && hour === 0) {
        descriptionText = "0h";
      }
      return descriptionText;
    },
    closeClick() {
      this.dialogLogVisible = false;
    },
    confirmClick() {
      this.$notify({
        type: "success",
        title: "温馨提示",
        message: "您选择了"
      });
      // this.holidayLogVisible = false;
      // this.$emit("getTripOccupyValue", this.selectData);
    },
    // 跳转业务建模
    businessDialogCick(val) {
      const businessKey = val?.row?.businessKey;
      if (businessKey?.length > 0) {
        let params = {
          templateId: businessKey
        };
        params = Object.assign(params, { id: businessKey });
        this.businessParams = viewBusinessDialogParams(params);
        this.businessDialog = true;
      }
    },
    // 筛选
    handleFilterSortChange({ filterData, sortData }) {
      // console.log(filterData);
      // console.log(sortData);
      this.filterParams = {
        filterFields: filterData,
        sortField: sortData
      };
      this.requestDataWithFilter();
      // this.pageModel.pageNo = 1;
      // this.pageModel.filterFields = filterData;
      // this.pageModel.sortField = sortData;
      // this.getLogList();
    }
  }
};
</script>

<style lang="scss" scoped>
.body-contain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 1100px;
  min-height: 635px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 4px;

  .title {
    position: absolute;
    left: 20px;
    top: 20px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 14px;
    height: 14px;
  }
  .table-contain {
    /*border: 1px solid #dce5ec;*/
    margin: 52px 20px 15px 20px;
    min-height: 511px;
    /*overflow-y: auto;*/
  }
  .pagination {
    margin-left: 8px;
    margin-right: 8px;
  }
}
.my-dialog {
  & /deep/ .el-dialog__header {
    padding: 0;
    margin: 0;
    height: 0;
  }
  & /deep/ .el-dialog__body {
    padding: 0;
    margin: 0;
  }
}

</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-contain" },
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "", "show-close": false, visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "body-contain" }, [
            _c("span", { staticClass: "title" }, [_vm._v("请假明细")]),
            _c(
              "div",
              { on: { click: _vm.closeClick } },
              [
                _c("en-icon", {
                  staticClass: "close-icon",
                  style: { fill: "#A9B5C6" },
                  attrs: { name: "guanbi-danchuang", size: "14px" },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table-contain" },
              [
                _c(
                  "en-table",
                  { attrs: { border: "", stripe: "", data: _vm.tableData } },
                  [
                    _c("en-table-column", {
                      attrs: { type: "index", label: "序号", width: "70" },
                    }),
                    _c("en-table-column", {
                      attrs: {
                        prop: "name",
                        label: "姓名",
                        width: "137",
                        sortable: "",
                      },
                    }),
                    _c("en-table-column", {
                      attrs: {
                        prop: "depart",
                        label: "部门",
                        width: "137",
                        sortable: "",
                      },
                    }),
                    _c("en-table-column", {
                      attrs: {
                        prop: "type",
                        label: "请假类型",
                        width: "124",
                        sortable: "",
                      },
                    }),
                    _c("en-table-column", {
                      attrs: {
                        prop: "date",
                        label: "请假时间",
                        width: "114",
                        sortable: "",
                      },
                    }),
                    _c("en-table-column", {
                      attrs: {
                        prop: "timeLong",
                        label: "请假时长(h)",
                        width: "137",
                        sortable: "",
                      },
                    }),
                    _c("en-table-column", {
                      attrs: { prop: "remark", label: "备注" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
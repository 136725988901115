<!--考勤日历主页面(左侧日历+右侧打卡记录)-->
<template>
  <div class="attendance-page">
    <!-- 左侧日历面板区域 -->
    <div
      class="attendance-page-main"
      :class="{ 'with-details': showAttendanceDetails }"
    >
      <div class="attendance-title">
        <div class="attendance-title-left">
          <en-icon  class="back-icon" name="iconfanhuishangcengji" size="18px"
              color="#A9B5C6" @click.native="goBack"></en-icon>
          <div class="label">
            考勤详情
          </div>
        </div>
        <div class="attendance-title-right" @click="showAttendanceList">
          <en-icon name="bianjilishi" size="14"></en-icon>
          <div class="label">
            统计
          </div>
        </div>
      </div>
      <!-- 头部年月信息及月份切换按钮 -->
      <div class="attendance-header">
        <en-icon color="#A9B5C6" name="xiayiye1" size="10" @click.native="changeMonth(-1)">
        </en-icon>
        <en-icon color="#A9B5C6" name="iconriqixuankuang" size="16" class="dateicon">
        </en-icon>
        <div class="attendance-month">
          {{ dateInfo.year + "年" + dateInfo.month }}
        </div>
        <en-icon color="#A9B5C6" name="xiayiye" size="10" @click.native="changeMonth">
        </en-icon>
      </div>
      <!-- 星期排列 -->
      <div class="attendance-week-list">
        <div v-for="item in weekArr" :key="item" class="attendance-week-item">
          {{ item }}
        </div>
      </div>
      <!-- 考勤日历数据面板区域 -->
      <div class="attendance-daily-list-contain">
        <div class="attendance-daily-list">
          <div
            v-for="item in calendarDataList"
            :key="item.timeStamp"
            class="attendance-daily-item"
            :class="{
              'other-month': item.isOtherMonth,
              'week-day': item.weekday === 6 || item.weekday === 0,
              'to-day': item.timeStamp === dateInfo.timeStamp,
              worked: item.timeStamp < dateInfo.timeStamp,
              selected: item.timeStamp === selectedDate.timeStamp
            }"
            @click.stop="showDetails(item)"
          >
            <!--日期显示-->
            <div class="attendance-daily-item-label">
              {{ item.date }}
            </div>
            <div class="attendance-daily-status-contain">
              <!--正/异常简称-->
              <div
                v-for="subItem in item.statusList"
                :key="subItem.id"
                class="attendance-daily-status-name"
                :class="{
                  late: subItem.status === 2 || subItem.status === 3,
                  abnormal: subItem.status === 11 || subItem.status === 12,
                  travel: subItem.status === 15 || subItem.status === 16,
                  askLeave: subItem.status === 14,
                  overtime: subItem.status === 18
                }"
              >
                {{ subItem.statusName }}
              </div>
              <!--正/异常小圆点-->
              <div
                v-if="!(item.isShowDetail) && (item.type === 1 || item.type === 3)"
                class="attendance-daily-status-circle"
                :class="{
                  abnormal: item.type === 3,
                  normal: item.type === 1
                }"
              ></div>
              <!--排班显示,暂时隐藏-->
              <div v-if="item.isShowSchedule && item.scheduleDetail" class="attendance-daily-item-type">
                {{ item.scheduleDetail }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <attendance-details
      v-model="showAttendanceDetails"
      :date-info="selectedDate"
      :att-model="attModelData"
      @reloadRequestRecordData="reloadRequestRecordData"
      @reloadCurrentRecordData="reloadCurrentRecordData"
    >
    </attendance-details>
  </div>
</template>

<script>
import enfryComponents from "heluo-component";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import { enEqual } from "@/tools/compare";
import attData from "./signInData";
// 引入组件库
import attendanceDetails from "./details";
// 引入组件库中的日历相关公共方法
const { datePickerInit } = enfryComponents.$tools;

export default {
  name: "Attendance",
  components: { attendanceDetails },
  data() {
    return {
      attModelData: {},
      // infoRecordList: [],
      dateRange: {},
      calendarDataList: [], // 日历显示数据
      dateInfo: {
        year: null,
        month: null,
        date: null,
        timeStamp: ""
      },
      weekArr: ["日", "一", "二", "三", "四", "五", "六"],
      selectedDate: {},
      showAttendanceDetails: false
    };
  },
  created() {
    this.getDateInfo();
    this.requestAttConfig();
    this.requestRecordDataList(this.dateRange);
  },
  mixins: [attData],
  mounted() {
    // this.getDateInfo();
  },
  watch: {
    infoRecordList() {
      if (dayjs(this.dateRange.startDate).isSame(this.dateRange.endDate)) {
        const obj = cloneDeep(this.selectedDate);
        // console.log(obj, this.infoRecordList);
        if (this.infoRecordList?.length > 0) {
          obj.recordInfo = this.infoRecordList[0];
        }
        this.selectedDate = obj;
      } else {
        this.handleRecordsToList();
        // this.infoRecordList.forEach((item) => {
        //   let month = `${this.selectedDate.month}`;
        //   if (this.selectedDate.month < 10 || this.selectedDate.month.length === 1) {
        //     month = `0${month}`;
        //   }
        //   let date = `${this.selectedDate.date}`;
        //   if (this.selectedDate.date < 10 || this.selectedDate.date.length === 1) {
        //     date = `0${date}`;
        //   }
        //   const selectedDateString = `${this.selectedDate.year}-${month}-${date}`;
        //   if (item?.workDate === selectedDateString) {
        //     const obj = cloneDeep(this.selectedDate);
        //     obj.recordInfo = item;
        //     this.selectedDate = obj;
        //   }
        // });
      }
    }
  },
  methods: {
    /**
     * @description 获取时间日期信息
     */
    getDateInfo() {
      const d = new Date();
      this.dateInfo.year = d.getFullYear();
      this.dateInfo.month = d.getMonth() + 1;
      this.dateInfo.date = d.getDate();
      this.dateInfo.timeStamp = datePickerInit.getTimeStamp(
        this.dateInfo.year,
        this.dateInfo.month,
        this.dateInfo.date
      );
      this.getAttendanceList();
    },
    // 更新查询日期区间
    updateDateRange(value) {
      const date = new Date(value); // 当前日期
      const month = date.getMonth(); // 当前月
      const year = date.getFullYear(); // 当前年
      // 本月的开始时间
      const monthStartDate = new Date(year, month, 1);
      // 本月的结束时间
      const monthEndDate = new Date(year, month + 1, 0);
      this.dateRange = {
        startDate: dayjs(monthStartDate).format("YYYY-MM-DD"),
        endDate: dayjs(monthEndDate).format("YYYY-MM-DD")
      };
      console.log(this.dateRange);
    },
    // 处理考勤日历显示数据
    handleRecordsToList() {
      this.calendarDataList.forEach((item) => {
        this.updateAttStatus(item);
        const dateString = dayjs(`${item.year}-${item.month}-${item.date}`).format("YYYY-MM-DD");
        const todayString = dayjs(new Date()).format("YYYY-MM-DD");
        if (item.timeStamp > this.dateInfo.timeStamp && dateString !== todayString) {
          item.isShowSchedule = false;// 需求不显示班次
          const workList = ["早", "中", "晚"];
          const random = Math.floor(Math.random() * 3);
          const work = workList[random];
          item.scheduleDetail = item.timeStamp > this.dateInfo.timeStamp ? work : "";
        }
      });
      this.$forceUpdate();
    },
    // 处理考勤日历单天显示数据
    updateAttStatus(obj) {
      const dateString = `${obj.year}-${obj.month}-${obj.date}`;
      this.infoRecordList.forEach((item) => {
        const tmpObj = { ...item };
        if (dayjs(dateString).isSame(tmpObj.workDate)) {
          obj.recordInfo = tmpObj;
          let isAbnormal = false;
          let isMustAbnormal = false;
          let isInitStatus = 0;
          const abnormalList = [];
          for (let i = 0; i < tmpObj.infoRecords.length; i++) {
            const infoEntity = tmpObj.infoRecords[i];
            if (enEqual(infoEntity.status, -1)) {
              isInitStatus += 1;
            }

            if (!isMustAbnormal && (enEqual(infoEntity.status, 5)
              || enEqual(infoEntity.status, 17))) {
              isMustAbnormal = true;
              break;
            }

            if (!isAbnormal && this.isAbnormal(infoEntity.status) && !enEqual(infoEntity.status, -1)) {
              isAbnormal = true;
            }

            if (this.isAbnormal(infoEntity.status) && !enEqual(infoEntity.status, -1)) { // 异常简称
              const shortName = this.statusShortName(infoEntity.status);
              if (!this.listIsContainName(abnormalList, shortName)) {
                abnormalList.push(
                  {
                    status: infoEntity.status,
                    statusName: shortName
                  }
                );
              }
            }
          }
          for (let i = 0; i < tmpObj.attRecords.length; i++) {
            const infoEntity = tmpObj.attRecords[i];

            if (enEqual(infoEntity.status, -1)) {
              isInitStatus += 1;
            }

            if (!isMustAbnormal && (enEqual(infoEntity.status, 5)
              || enEqual(infoEntity.status, 17))) {
              isMustAbnormal = true;
              break;
            }

            if (!isAbnormal && this.isAbnormal(infoEntity.status) && !enEqual(infoEntity.status, -1)) {
              isAbnormal = true;
            }

            if (this.isAbnormal(infoEntity.status) && !enEqual(infoEntity.status, -1)) { // 异常简称
              const shortName = this.statusShortName(infoEntity.status);

              if (!this.listIsContainName(abnormalList, shortName)) {
                abnormalList.push({
                  status: infoEntity.status,
                  statusName: shortName
                });
              }
            }
          }
          if (abnormalList.length > 0 && !isMustAbnormal) {
            obj.statusList = [];
            obj.statusList.push(...abnormalList);
            obj.isShowDetail = true;
          } else {
            const normalList = [];
            if (parseFloat(tmpObj.tripTimes) > 0.0) {
              normalList.push({
                status: 16,
                statusName: "出"
              });
            }
            if (parseFloat(tmpObj.outsideTimes) > 0.0) {
              normalList.push({
                status: 15,
                statusName: "外"
              });
            }
            if ((parseFloat(tmpObj.leaveTimes) - parseFloat(tmpObj.sellTimes)) > 0.0) {
              normalList.push({
                status: 14,
                statusName: "假"
              });
            }
            if (parseFloat(tmpObj.overTimes) > 0.0) {
              normalList.push({
                status: 18,
                statusName: "加"
              });
            }
            if (!isMustAbnormal) {
              obj.statusList = [];
              obj.statusList.push(...normalList);
              if (obj.statusList.length > 0) {
                obj.isShowDetail = true;
              }
            }
          }

          if (tmpObj.infoRecords.length > 0 || tmpObj.attRecords.length > 0) {
            obj.type = isAbnormal ? 2 : 1;

            // status都为-1时,为当做无考勤记录
            if (isInitStatus === (tmpObj.infoRecords.length + tmpObj.attRecords.length)) {
              obj.type = 0;
            }
          } else {
            obj.type = 0;
          }

          if (isMustAbnormal) {
            obj.type = 3;
          }
        }
      });
    },
    // 考勤简称是否存在list
    listIsContainName(list, name) {
      for (let i = 0; i < list.length; i++) {
        const obj = list[i];
        if (obj.statusName === name) {
          return true;
        }
      }
      return false;
    },

    /**
       * @description 生成日期面板
       */
    getAttendanceList() {
      this.calendarDataList = [];
      const dateList = datePickerInit.getWholeMonthData(
        this.dateInfo.year,
        this.dateInfo.month,
        0,
        true
      );
      this.calendarDataList.push(...dateList);
      const obj1 = this.calendarDataList[0];
      const obj2 = this.calendarDataList[this.calendarDataList.length - 1];
      this.dateRange = {
        startDate: `${obj1.year}-${obj1.month}-${obj1.date}`,
        endDate: `${obj2.year}-${obj2.month}-${obj2.date}`
      };
    },
    /**
       * @description 月份切换
       * @param num
       */
    changeMonth(num) {
      if (num === -1) {
        if (parseInt(this.dateInfo.month, 10) === 1) {
          this.dateInfo.month = 12;
          this.dateInfo.year -= 1;
        } else {
          this.dateInfo.month -= 1;
        }
      } else if (parseInt(this.dateInfo.month, 10) === 12) {
        this.dateInfo.month = 1;
        this.dateInfo.year += 1;
      } else {
        this.dateInfo.month += 1;
      }
      this.getAttendanceList();
      this.requestRecordDataList(this.dateRange);
    },
    /**
       * @description 从哪里来，回哪里去
       */
    goBack() {
      this.showAttendanceDetails = false;
      // this.$router.push({ url: "/user/home" });
      this.$router.go(-1);
    },
    /**
       * @description 查看指定日期的考勤详情
       * @param itemData
       */
    showDetails(itemData) {
      this.showAttendanceDetails = true;
      const dateString = `${itemData.year}-${itemData.month}-${itemData.date}`;
      this.dateRange = {
        startDate: dateString,
        endDate: dateString
      };
      itemData.dateString = dayjs(this.dateRange.startDate).format("YYYY-MM-DD");
      this.selectedDate = itemData;
      this.requestRecordDataList(this.dateRange);
    },
    /**
       * @description 切换至考勤汇总界面
       */
    showAttendanceList() {
      this.$router.push({ name: "myAttendanceChart", params: this.attModelData });
    },
    // 获取考勤配置
    configAction() {
      this.requestAttConfig();
      console.log(this.attModelData);
    },
    // 获取考勤打卡数据
    recordAction() {
      this.requestRecordsData();
      console.log(this.infoRecordList);
    },
    // 回调刷新数据
    reloadRequestRecordData() {
      this.showAttendanceDetails = false;
      this.getAttendanceList();
      this.requestRecordDataList(this.dateRange);
    },
    reloadCurrentRecordData() {
      this.requestRecordDataList(this.dateRange);
    }
  }
};
</script>

<style lang="scss">
  .attendance-page {
    user-select: none;
    // background-color: #1d2532;
    position: relative;
    width: 100%;
    overflow: hidden;

    .attendance-page-main {
      background-color: #ffffff;
      height: 100%;
      width: 100%;
      transition: 0.5s ease-in-out width;
      -webkit-border-radius: 4px 4px 4px;
      -moz-border-radius: 4px 4px 4px;
      border-radius: 4px 4px 4px;
      overflow: hidden;

      &.with-details {
        width: calc(100% - 395px);
      }

      .attendance-title {
        line-height: 54px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e8ecf2;

        .en-icon {
          font-size: 16px;
          margin: 17px 10px 17px 0;
        }

        .attendance-title-left {
          display: flex;
          justify-content: flex-start;

          .label {
            font-size: 14px;
            font-weight: bold;
            color: #333333;
          }
        }

        .attendance-title-right {
          display: flex;
          justify-content: flex-start;
          cursor: pointer;
          align-items: center;

          .en-icon {
            color: #3e90fe;
          }

          .label {
            font-size: 12px;
          }
        }
      }

      .attendance-header {
        width: 100%;
        font-size: 12px;
        color: #636c78;
        align-items: center;
        display: flex;
        justify-content: flex-start;
        line-height: 42px;
        background-color: #f5f7fa;
        border-bottom: 1px solid #e8ecf2;
        padding: 0 20px;

        .en-icon {
          margin: 15px 0;
          cursor: pointer;

          &:hover {
            color: #3e90fe;
          }
        }
        .dateicon{
          margin:0 0 0 12px;
        }
        .attendance-month {
          font-weight: bold;
          font-size: 12px;
          color: #1E1E1E;
          margin: 0 12px 0 8px;
        }
      }

      .attendance-week-list {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        background-color: #fff;
        line-height: 52px;
        color: #333333;
        font-size: 13px;
        border-bottom: 1px solid #e8ecf2;
        -webkit-border-radius: 4px 4px 4px;
        -moz-border-radius: 4px 4px 4px;
        border-radius: 4px 4px 4px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        z-index: 2;

        .attendance-week-item {
          width: calc(100% / 7);
          text-align: center;
          border-right: 1px solid #dce5ec;

          &:nth-child(7n) {
            border-right: none;
          }
        }
      }
      .attendance-daily-list-contain {
        overflow: scroll;
        height: calc(100% - 145px);
        width: calc(100% + 4px);
      }

      .attendance-daily-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;

        .attendance-daily-item {
          width: calc(100% / 7);
          border-right: 1px solid #dce5ec;
          border-bottom: 1px solid #dce5ec;
          height: 130px;
          font-size: 12px;
          cursor: pointer;

          .attendance-daily-item-label {
            width: 100%;
            align-content: center;
            line-height: 30px;
            height: 30px;
            font-size: 14px;
            color: #1A1C1E;
            text-align: right;
            padding: 13px 17px 0 0;
          }

          .attendance-daily-status-contain {
            height: 55px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            .attendance-daily-status-name {
              width: 18px;
              height: 18px;
              color: #ffffff;
              font-size: 12px;
              text-align: center;
              line-height: 18px;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              border-radius: 3px;
              background-color: #26c393;
              margin: 0 3px;
              &.late {
                background-color: #ffad2c;
              }
              &.abnormal {
                background-color: #f76b6b;
              }
              &.askLeave {
                background-color: #26c393;
              }
              &.overtime {
                background-color: #9367eb;
              }
              &.travel {
                background-color: #49bef2;
              }
            }

            .attendance-daily-status-circle {
              width: 8px;
              height: 8px;
              -webkit-border-radius: 100%;
              -moz-border-radius: 100%;
              border-radius: 100%;
              &.abnormal {
                background-color: #f76b6b;
              }

              &.normal {
                background-color: #3e90fe;
              }
            }
          }

          .attendance-daily-item-status {
            width: 8px;
            height: 8px;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            margin: 0 auto;

            &.error {
              background-color: #f76b6b;
            }

            &.normal {
              background-color: #3e90fe;
            }

            &.with-label {
              width: 18px;
              height: 18px;
              color: #ffffff;
              font-size: 12px;
              text-align: center;
              line-height: 18px;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              border-radius: 3px;
              background-color: #26c393;
              margin-top: -5px;

              &.arrive-late,
              &.leave-early {
                background-color: #ffad2c;
              }

              &.absenteeism,
              &.abnormal {
                background-color: #f76b6b;
              }

              &.travel,
              &.field-work {
                background-color: #49bef2;
              }

              &.over-time {
                background-color: #9367eb;
              }
            }
          }

          .attendance-daily-item-type {
            color: #636c78;
          }

          &.week-day {
            background-color: #fbfbfb;
          }

          &.worked {
            // .attendance-daily-item-label {
            //   color: #91a1b7;
            // }
          }

          &.selected {
            background-color: #f5f9fd;
          }

          &.to-day {
            position: relative;
            .attendance-daily-item-label {
              width: 30px;
              height: 30px;
              line-height: 31px;
              position: absolute;
              top: 10px;
              right: 14px;
              text-align: center;
              background-color: #3e90fe;
              color: #ffffff;
              -webkit-border-radius: 100%;
              -moz-border-radius: 100%;
              border-radius: 100%;
              padding: 0;
            }
          }

          &:nth-child(7n) {
            border-right: none;
          }
        }
      }
    }
  }
</style>

import { request } from "en-js";
import { hrService } from "@/api";

export default {
  data() {
    return {
      holidayDataList: []
    };
  },
  methods: {
    // 假期余额
    @request(true)
    async requestMyHolidayBalance() {
      const setList = await hrService.queryTenantHolidaySet();
      const dataList = await hrService.queryMyHolidayBalance();
      const list = [];
      for (let i = 0; i < dataList.length; i++) {
        const obj = dataList[i];
        for (let j = 0; j < setList.length; j++) {
          const set = setList[j];
          if (obj.vacationRefId === set.id) {
            const dataObj = {
              data: obj,
              set
            };
            const hours = parseFloat(obj.vacHours);
            const conTime = parseFloat(set.conTime);
            const day = parseInt(hours / conTime, 10);
            const hour = Math.round(parseFloat(hours % conTime) * 100) / 100;
            let descriptionText = "";
            if (day > 0) {
              descriptionText = `${day}d`;
            }
            if (hour > 0) {
              descriptionText = `${descriptionText + hour}h`;
            }
            if (day <= 0 && hour <= 0) {
              descriptionText = "0h";
            }
            dataObj.descriptionText = descriptionText;
            dataObj.name = obj.vacName;
            dataObj.value = hours;
            dataObj.conTime = conTime;
            dataObj.day = day;
            dataObj.hour = hour;
            list.push(dataObj);
          }
        }
      }
      this.holidayDataList = [...list];
    },

    // 假期日志
    async requestMyHolidayRecords(params) {
      const logList = await hrService.queryMyHolidayRecords(params);
      return logList;
    },

    // 加班记录
    async requestMdMyWorkOverTimeInfo(params) {
      const overtimeList = await hrService.queryMdMyWorkOverTimeInfo(params);
      return overtimeList;
    }
  }
};

<!--
 * @Author: 张家衡
 * @Date: 2021-01-28 10:56:30
 * @LastEditTime: 2021-11-11 10:30:39
 * @LastEditors: Please set LastEditors
 * @Description: 考勤查询 报表的 第一层穿透 考勤明细
 * @FilePath: \user\src\views\hr\report\attendDetails.vue
-->
<template>
  <div>
    <en-dialog :visible="visiable" :close-on-click-modal="false" :modal="false" id="problem-z-index-important" @close="closeDialog" :append-to-body="true" width="1100px" style="text-aglin:left;">
      <div slot="title">
        <div class="title-box">
          <div>{{ title }}</div>
          <div>
            <en-icon style="margin-right:30px;color:#26C393;cursor: pointer;" title="导出" size="18px" fill="#26C393" name="daochu-danchuang" @click.native.stop="dataExport"></en-icon>
          </div>
        </div>
      </div>
      <div class="box" v-en-loading="loading">
        <en-table
          v-if="colums && colums.length && tableData && tableData.records"
          :data="tableData.records"
          :height="500"
          :page-config="{ pageModel: tableData, changeMethod: handlePageChanged }"
          @filter-sort-change="handleTableFilterChange"
          @cell-click="cellClickEvent"
        >
          <en-table-column type="index" width="80"></en-table-column>
          <en-table-column
            v-for="(item, index) in colums"
            :key="index"
            :prop="item.id"
            :label="item.name"
            :fieldType="item.colType"
            :data="item"
            :filter-sort-config="{ filterable: item.noSearch == '0', sortable: item.noSort == '0' }"
            :filter-render="{ name: item.fieldType == 1 ? 'ElInput' : 'EnInputRange', attrs: { placeholder: '请输入' + item.name + '...' } }"
          >
            <template v-slot="{ row, column }">
              <el-tag v-if="column.field == 'isDealName' && row.isDealName == '已处理'" style="color:#49bef2;background:rgba(70,148,223,0.1);" @click.native.stop="toModel(row)">
                {{ row[column.field] }}
              </el-tag>
              <el-tag v-else-if="column.field == 'isDealName' && row.billStatus" style="color:#49bef2;background:rgba(70,148,223,0.1);" @click.native.stop="toModel(row)">
                {{ billStatus[row.billStatus] }}
              </el-tag>
              <el-tag v-else-if="column.field == 'isDealName' && row.isDealName == '未处理'" style="color:#606060;background:rgba(99,108,120,0.1);">
                {{ row[column.field] }}
              </el-tag>
              <span v-else-if="column.data.timeFormat == 9">{{ getFormatShowValue(row[column.field], column.data.timeFormat) }}</span>
              <span v-else>{{ row[column.field] }}</span>
            </template>
          </en-table-column>
        </en-table>
      </div>
    </en-dialog>
    <recordDetails v-if="recordDetailsFlg" :params="recordDetailsData" :show.sync="recordDetailsFlg" @toShow="toShow"></recordDetails>
  </div>
</template>

<script>
import { hrService } from "@/api";
import { readOnlyListEntranceRoute } from "@/components/businessDetail/helper.js";
import Utils from "@/components/en-table-extra/data/utils.js";
import recordDetails from "./recordDetails";

export default {
  name: "attendDetails",
  components: { recordDetails },
  props: {
    // 公共数据
    params: {
      type: Object,
      default() {
        return {};
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visiable: false,
      tableData: {},
      colums: [],
      // 业务类型（1-考勤记录，2-请假单，3-加班单，4-出差单，5-外勤单）
      businessType: "1",
      loading: true,
      titleMap: {
        0: "应出勤考勤明细",
        1: "实出勤考勤明细",
        2: "迟到考勤明细",
        3: "早退考勤明细",
        4: "脱岗明细",
        5: "未打卡考勤明细",
        8: "出差明细",
        9: "加班明细",
        10: "请假明细",
        11: "异常考勤明细",
        12: "旷工考勤明细",
        14: "请假明细",
        15: "外勤明细",
        16: "出差明细",
        17: "缺勤考勤明细",
        "2,3,5,11,12,17": "异常考勤明细"
      },
      title: "",
      billStatus: {
        "000": "未提交",
        "001": "待处理",
        "002": "审批中",
        "006": "已审批",
        "004": "已终止",
        "003": "已结束",
        "007": "已作废",
        "008": "已提交"
      },
      // 打开明细弹框
      recordDetailsFlg: false,
      recordDetailsData: {}
    };
  },
  mounted() {
    this.title = this.titleMap[this.params.status];
    this.init();
    this.visiable = this.show;
    this.queryAttDetailPageModel({ page: 1, pageSize: 10 });
  },
  methods: {
    // 处理表头
    init() {
      let colums = [
        {
          id: "name",
          name: "姓名",
          colType: "1",
          noSort: "0",
          noSearch: "1"
        },
        {
          id: "no",
          name: "工号",
          colType: "1",
          noSort: "0",
          noSearch: "1"
        },
        {
          id: "deptName",
          name: "部门",
          colType: "1",
          noSort: "0",
          noSearch: "1"
        },
        {
          id: "workTime",
          name: "应打卡时间",
          colType: "7",
          noSort: "0",
          noSearch: "1",
          timeFormat: 9
        },
        {
          id: "recordTime",
          name: "实际打卡时间",
          colType: "7",
          noSort: "0",
          noSearch: "1",
          timeFormat: 9
        },
        {
          id: "address",
          name: "地点",
          colType: "1",
          noSort: "0",
          noSearch: "1"
        },
        {
          id: "ipAddress",
          name: "ip地点",
          colType: "1",
          noSort: "0",
          noSearch: "1"
        },
        {
          id: "wifiName",
          name: "wifi名称",
          colType: "1",
          noSort: "0",
          noSearch: "1"
        },
        {
          id: "remark",
          name: "备注",
          colType: "1",
          noSort: "0",
          noSearch: "1"
        },
        {
          id: "statusName",
          name: "考勤结果",
          colType: "1",
          noSort: "0",
          noSearch: "1"
        },
        {
          id: "isDealName",
          name: "处理状态",
          colType: "1",
          noSort: "0",
          noSearch: "1"
        }
      ];
      if (["2", "3", "4", "5", "11", "12", "17", "2,3,5,11,12,17"].indexOf(this.params.status) > -1) {
        // 未打卡
        // 迟到早退脱岗
        this.businessType = "1";
      } else if (this.params.status === "10" || this.params.status === "14") {
        // 请假
        colums = [
          {
            id: "name",
            name: "姓名",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "deptName",
            name: "部门",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "vacName",
            name: "请假类型",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "leaveSectionTime",
            name: "请假时间",
            colType: "7",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "leaveWhenLong",
            name: "请假时长（h）",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "remark",
            name: "备注",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          }
        ];
        this.businessType = "2";
      } else if (this.params.status === "8" || this.params.status === "16") {
        // 出差
        colums = [
          {
            id: "name",
            name: "姓名",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "deptName",
            name: "部门",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "tripType",
            name: "类型",
            colType: "1",
            noSort: "1",
            noSearch: "1"
          },
          {
            id: "travelTime",
            name: "出差时间",
            colType: "7",
            noSort: "1",
            noSearch: "1"
          },
          {
            id: "tripTimes",
            name: "出差时长",
            colType: "1",
            noSort: "1",
            noSearch: "1"
          },
          {
            id: "remark",
            name: "备注",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          }
        ];
        this.businessType = "4";
      } else if (this.params.status === "9") {
        // 加班
        colums = [
          {
            id: "name",
            name: "姓名",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "deptName",
            name: "部门",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "overtimeType",
            name: "加班类型",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "overTimeSectionTime",
            name: "加班时间",
            colType: "7",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "overTimeWhenLong",
            name: "加班时长（h）",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "remark",
            name: "备注",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          }
        ];
        this.businessType = "3";
      } else if (this.params.status === "15") {
        // 外出
        colums = [
          {
            id: "name",
            name: "姓名",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "deptName",
            name: "部门",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "outsideTime",
            name: "外勤时间",
            colType: "7",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "outsideAddress",
            name: "外勤地点",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          },
          {
            id: "remark",
            name: "备注",
            colType: "1",
            noSort: "0",
            noSearch: "1"
          }
        ];
        this.businessType = "5";
      }
      this.colums = colums;
      this.params.businessType = this.businessType;
      if (this.params.status === "1") {
        this.params.status = "1,2,3,7,11,12,15,16";
      }
    },
    // 查询数据
    async queryAttDetailPageModel(page) {
      this.loading = true;
      const p = { ...this.params, ...page };
      const res = await (hrService.queryAttDetailPageModel(p) || {});
      if (this.businessType === "4") {
        // 出差
        res.records.forEach((element) => {
          // ripTimes < 0  销出差 出差/1
          if (element.ripTimes < 0) {
            element.tripType = "销出差";
          } else {
            element.tripType = "出差";
          }
          element.tripTimes += "(h)";
          element.travelTime = `${element.tripStartDate}至${element.tripEndDate}`;
        });
      } else if (this.businessType === "3") {
        // 加班
        res.records.forEach((element) => {
          element.overTimeWhenLong += "(h)";
          const s = element.overTimeSectionTime.split(",");
          const ss = `${s[0].substring(0, 16)}至${s[1].substring(0, 16)}`;
          element.overTimeSectionTime = ss;
        });
      } else if (this.businessType === "2") {
        // 请假
        res.records.forEach((element) => {
          const s = element.leaveSectionTime.split(",");
          const ss = `${s[0].substring(0, 16)}至${s[1].substring(0, 16)}`;
          element.leaveSectionTime = ss;
        });
      } else if (this.businessType === "5") {
        // 外出
      } else if (this.businessType === "1") {
        // 考勤
      }
      this.tableData = res;
      this.loading = false;
      console.log(res);
    },
    // 分页事件
    handlePageChanged(obj) {
      this.queryAttDetailPageModel(obj);
    },
    // 排序
    handleTableFilterChange(obj) {
      const con = {
        page: 1,
        pageSize: 10,
        sortField: {}
      };
      const list = obj.$table.$refs.filterWrapper.filterStore.dataList;
      let sortField = {};
      list.forEach((element) => {
        if (element.type === "sort") {
          // 排序字段
          sortField = {
            area: "main",
            field: element.field,
            nameVariable: element.field,
            sortType: element.sortType
          };
        }
      });

      con.sortField = JSON.stringify(sortField);
      this.queryAttDetailPageModel(con);
    },
    // 跳到业务建模 考勤明细 businessType=1 处理状态的 单独点击事件
    toModel(row) {
      console.log(row);
      if (row.formId && row.mainId) {
        // 有 业务建模模板id 跳业务建模
        this.visiable = false;
        this.$router.push(
          readOnlyListEntranceRoute({
            id: row.mainId,
            businessType: 2,
            templateId: row.formId,
            templateName: ""
          })
        );
      } else if (row.mainId) {
        // 只有mainId跳行程单
        // 跳到行程单详情
        // this.$router.push({ path: , query: { tripId: row.mainId } });
        window.open(`/business-travel/trip/tripDetail?tripId=${row.mainId}`, "_blank");
      }
    },
    // 单元格点击 行点击
    cellClickEvent(obj) {
      console.log(obj);
      if (this.businessType === "1") {
        // 弹框打卡记录
        const filterFields = [{ nameVariable: "infoRecordId", value: obj.row.id, fieldType: 1 }];
        this.recordDetailsData = {
          filterFields: JSON.stringify(filterFields),
          day: this.params.day
        };
        this.recordDetailsFlg = true;
      } else if (["2", "3", "5"].indexOf(this.businessType) > -1) {
        // 请假 加班 外出 跳转 业务建模
        this.visiable = false;
        this.$router.push(
          readOnlyListEntranceRoute({
            id: obj.row.id,
            businessType: 2,
            templateId: obj.row.templateId,
            templateName: ""
          })
        );
      } else if (this.businessType === "4") {
        // 出差
        if (obj.row.formType === 1) {
          // 表单
        } else if (obj.row.formType === 3) {
          // 行程单
          window.open(`/business-travel/trip/tripDetail?tripId=${obj.row.id}`, "_blank");
        } else {
          this.visiable = false;
          this.$router.push(
            readOnlyListEntranceRoute({
              id: obj.row.id,
              businessType: 2,
              templateId: obj.row.formId,
              templateName: ""
            })
          );
        }
      }
    },
    // 关闭回调
    closeDialog() {
      this.$emit("toShow", false);
    },
    toShow() {
      this.recordDetailsFlg = false;
    },
    // 导出
    async dataExport() {
      if (this.tableData.records && this.tableData.records.length) {
        const p = { ...this.params, requestType: "stream", isNodes: "0" };
        if (this.params.status === "1,2,3,7,11,12,15,16") {
            // 实出勤
            p.exportType = "1";
        } else if (this.params.status === "2,3,5,11,12,17") {
            // 异常总数
            p.exportType = "2";
        }
        const res = await hrService.queryAttDetailsExport(p);
        const { headers } = res;
        const cd = headers["content-disposition"] || "";
        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/vnd.ms-excel" }));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.download = decodeURIComponent(cd.split("=")[1]);
        // document.body.appendChild(link);
        link.click();
        // document.body.removeChild(link);
      } else {
        this.$message({
          showClose: true,
          message: "当前无数据",
          type: "warning"
        });
      }
    },
    // 时间格式
    getFormatShowValue(value, timeFormat) {
      return Utils.dateFormatString(value, timeFormat);
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  margin: 0 auto;
  width: 100%;
  min-height: 550px;
  padding: 0 0 17px 0;
  font-size: 12px;
  line-height: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(99, 108, 120, 1);
}
.title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vxe-table-box {
  /deep/.main-box {
    margin-right: 0 !important;
  }
}
</style>
